@import '@core/styles/vars';

.RequestsPopover {
  background: var(--color-bg-page);
  max-width: 250px;

  &-content {
    border: 1px solid var(--color-border-default);
    border-radius: var(--border-radius);
    max-height: var(--button-sm);
    overflow: hidden;
    padding: var(--xs) var(--sm);

    @media (prefers-reduced-motion: no-preference) {
      transition:
        max-height var(--transition-slow) ease-in-out,
        box-shadow var(--transition-slow) ease-in-out;
    }

    &:hover {
      cursor: pointer;
    }

    &_expanded {
      background: var(--color-bg-page);
      box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.05);
      max-height: 500px;
    }
  }

  &-header {
    height: 20px;
    margin-bottom: 0;
    width: 100%;

    &_expanded {
      margin-bottom: var(--sm);
    }

    &-title {
      color: var(--color-text-minimum);
      font-size: 12px;
      margin: 0;
    }

    &-totals {
      color: var(--color-text-default);
      font-size: 12px;
      margin-left: auto;
    }

    &-icon {
      color: var(--color-text-minimum);
    }
  }

  &-sparkline {
    padding: 0;
  }

  &-graph {
    --MetricsGraph-padding: 0;

    height: 100%;
  }

  &-graph-box {
    padding: 0;
    position: relative;

    &-empty {
      align-items: center;
      color: var(--gray70);
      font-size: 12px;
      min-height: 100px;
      text-transform: uppercase;
    }
  }

  &-graph-tooltip {
    width: 150px;
    z-index: 99999;

    // Overriding TooltipItem styles
    :global {
      :first-child {
        .rm-TooltipItem-value {
          display: none;
        }
      }
    }
  }

  &-totals {
    display: grid;
    font-size: 14px;
    gap: var(--xs);
    grid-template-columns: 1fr max-content;
    margin: var(--sm) 0 0;

    dt {
      align-items: center;
      color: var(--color-text-minimum);
      display: flex;
      gap: var(--xs);
    }

    dd {
      align-items: center;
      color: var(--color-text-default);
      display: flex;
      justify-content: flex-end;
    }
  }
}
