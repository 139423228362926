@import '~@core/styles/vars';

.reference-flyout {
  .flyout {
    background: var(--white);
    padding: 0;
  }

  .reference-redesign {
    box-shadow: none;
  }
}

.rm-TagTooltip {
  display: inline-block;
}
