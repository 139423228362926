@import '~@core/styles/vars';
@import '~@core/styles/mixins/dark-mode';

.rm-Callbacks {
  --Callbacks-bg: var(--gray100);
  --Callbacks-bg-alt: var(--white);
  --Callbacks-border: var(--color-border-default);
  --Callbacks-color: var(--color-text-muted);

  @include dark-mode {
    --Callbacks-bg: var(--gray15);
    --Callbacks-bg-alt: var(--gray10);
  }

  .rm-ParamContainer {
    background: var(--Callbacks-bg-alt);
  }
}
