.DeprecationWarning {
  align-items: center;
  background-color: var(--red);
  color: var(--white);
  display: flex;
  justify-content: center;
  padding: var(--sm);
  
  &:hover {
    background-color: var(--red40);
    color: var(--white);
  }
}
