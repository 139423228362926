@import '~@readme/react/src/core/styles/vars';
@import '~@core/styles/mixins/dark-mode';

.rm-APIResponseSchemaPicker {
  --APIResponseSchemaPicker-bg: var(--gray100);
  --APIResponseSchemaPicker-bg-alt: var(--white);
  --APIResponseSchemaPicker-bg-hover: var(--white);
  --APIResponseSchemaPicker-border: var(--color-border-default);

  @include dark-mode {
    --APIResponseSchemaPicker-bg: var(--gray15);
    --APIResponseSchemaPicker-bg-alt: var(--gray10);
    --APIResponseSchemaPicker-bg-hover: var(--gray20);
  }

  // override dark theme select (maybe Ilias was right about this)
  .Select {
    background-image: url("data:image/svg+xml,%3Csvg width='17' height='15' viewBox='0 0 17 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.625 5L8.60711 1.25L4.58923 5' stroke='rgba(0,0,0,.25)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M4.58923 10L8.60711 13.75L12.625 10' stroke='rgba(0,0,0,.25)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  }
}
