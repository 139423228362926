@import '~@core/styles/vars.compat';
@import '~@core/styles/mixins/dark-mode';

.RequestExampleEditor {
  --RequestExampleEditor-bg: var(--gray15);
  --RequestExampleEditor-bg-rgb: #{$gray15-rgb};

  @include dark-mode {
    --RequestExampleEditor-bg: var(--gray15);

    border: 1px solid var(--color-border-default);
  }

  background: var(--RequestExampleEditor-bg);
  border: 1px solid transparent;
  border-radius: var(--border-radius-lg);
  box-shadow: var(--box-shadow-request, 0 1px 2px rgba(black, 0.05), 0 2px 5px rgba(black, 0.02));
  color: var(--white);
  overflow: hidden;
  width: 100%;

  &_disabled {
    opacity: 0.7;
  }

  &-header {
    background: var(--gray15);
    border-bottom: 1px solid rgba(white, 0.1);
    overflow: auto hidden;

    &-tabs {
      flex: 0 1 auto;
    }

    &-add {
      align-items: center;
      background: var(--gray15);
      border: 0;
      border-left: 1px solid rgba(white, 0.1);
      border-right: 1px solid transparent;
      cursor: pointer;
      display: flex;
      flex: 0 0 var(--button-sm);
      height: var(--button-sm);
      justify-content: center;
      margin-left: -1px;
      position: sticky;
      right: 0;
      width: var(--button-sm);
      z-index: 2;

      &:hover,
      &:focus,
      &:active {
        background: var(--gray20);
        border-right-color: rgba(white, 0.1);
      }
    }
  }

  &-code {
    display: flex;
    flex: 1 1 auto;
    width: 100%;

    // overriding some codemirror defaults for accessibility
    :global(.CodeEditor),
    :global(.CodeMirror.CodeMirror.CodeMirror) {
      height: 100%;
    }

    // overriding some codemirror defaults for accessibility
    :global(.CodeSnippet) {
      width: 100%;
    }

    // overriding theme gutters
    :global(.CodeMirror-gutters.CodeMirror-gutters) {
      background: var(--gray10);
    }
  }

  &-initial-state {
    --flex-gap: var(--sm) !important;

    flex-direction: column;
    padding: var(--sm);

    &-divider {
      background: rgba(white, 0.1);
      height: 1px;
      width: 100%
    }

    &-header {
      font-family: var(--font-family);
      font-size: 12px;
      font-weight: var(--font-weight-bold);
      letter-spacing: 0.05em;
    }

    &-icon {
      height: 16px;
      padding: var(--xs);
      width: 16px;

      &-container {
        background: var(--gray20);
        border: 1px solid rgba(white, 0.1);
        border-radius: var(--border-radius);
        box-sizing: border-box;
        width: 100%;
      }

      &-label {
        font-family: var(--font-family);
        font-size: 12px;
        font-weight: var(--font-weight-bold);
        margin-top: var(--sm);
        padding: var(--xs) var(--lg);
        text-align: center;
      }

      &-list {
        margin: var(--sm) 0;
      }
    }

  }
}