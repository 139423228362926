@import '~@core/styles/vars';
@import '~@core/styles/mixins/dark-mode';

.APIResponseSchemaPicker {
  --Accordion-gap: 0;

  $block: &;

  display: flex;
  flex-direction: column;
  height: auto;

  &-option {
    $option: &;

    align-items: stretch;
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    margin: 0;
    position: relative;
    text-align: left;

    &-toggle {
      background: var(--APIResponseSchemaPicker-bg);
      border: var(--border-width) solid var(--APIResponseSchemaPicker-border);
      border-radius: 0;
      padding: $sm;
      transition: box-shadow var(--transition-fast) var(--transition-timing);

      &:hover {
        background: var(--APIResponseSchemaPicker-bg-hover);
      }

      &:focus,
      &:focus-within,
      &:active {
        border-color: var(--blue);
        z-index: 1;
      }
    }

    &-callback {
      background: var(--APIResponseSchemaPicker-bg-alt);
    }

    + .APIResponseSchemaPicker-option {
      margin-top: calc(var(--border-width) * -1);
    }

    &:first-child #{$option}-toggle {
      border-top-left-radius: var(--border-radius-lg);
      border-top-right-radius: var(--border-radius-lg);
    }

    &:last-child {
      #{$option}-toggle {
        border-bottom-left-radius: var(--border-radius-lg);
        border-bottom-right-radius: var(--border-radius-lg);

        &:global(.active) {
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
        }
      }

      #{$block}-items {
        border-bottom: var(--border-width) solid var(--APIResponseSchemaPicker-border);
        border-bottom-left-radius: var(--border-radius-lg);
        border-bottom-right-radius: var(--border-radius-lg);
      }
    }

    &:only-child #{$option}-toggle {
      border-radius: var(--border-radius-lg);
    }

    &_hasContent {
      cursor: pointer;

      &:hover,
      &:active,
      &:focus {
        background: var(--APIResponseSchemaPicker-bg-hover);
      }

      &:focus {
        border-color: var(--color-input-border-active);
        box-shadow: 0 0 0 3px var(--color-input-border-focus);
        outline: 0;
        z-index: 1;

        .APIResponseSchemaPicker-maximize {
          color: var(--color-input-border-active);
        }
      }
    }
  }

  &-maximize {
    color: var(--color-text-minimum);
    font-size: var(--icon-md);
  }

  &-label {
    align-items: center;
    color: var(--color-text-muted);
    display: flex;
    font-size: 14px;
    font-weight: var(--font-weight-bold);
    text-transform: capitalize;

    &-text {
      margin-right: $xs;
    }
  }

  &-description {
    color: var(--color-text-minimum);
    font-size: 13px;
  }

  // Need this to override APISectionHeader without !important
  &-items :global(.rm-APISectionHeader.rm-APISectionHeader.rm-APISectionHeader) {
    margin-top: 0;
    padding-top: $sm;
  }
}

.ResponseModal {
  &-header {
    &-status {
      align-items: center;
      background: none;
      border: var(--border-width) solid var(--APIResponseSchemaPicker-border, transparent);
      border-radius: var(--border-radius-lg);
      display: inline-flex;
      font-family: var(--font-family);
      font-size: 14px;
      gap: 2px;
      margin-left: $md;
      padding: $xs;
      position: relative;

      &:hover {
        border-color: var(--color-input-border-hover);
      }

      &:active,
      &:focus {
        border-color: var(--color-input-border-active);
        box-shadow: 0 0 0 3px var(--color-input-border-focus);
      }

      &-icon {
        color: var(--color-text-minimum);
        font-size: $icon-sm;
        margin-left: $xs;
      }

      &_disabled {
        &:hover {
          border-color: var(--color-border-default);
        }

        .ResponseModal-header-status-icon {
          display: none;
        }
      }
    }

    &-select {
      height: 100%;
      left: 0;
      opacity: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }

  &-schema-top {
    :global(.readme-markdown) {
      border-bottom: 1px solid var(--color-border-default);
      padding-bottom: $md;
    }

    &:only-child :global(.readme-markdown) {
      border-bottom: 0;
    }
  }
}
