@import '~@core/styles/vars';
@import '~@core/styles/mixins/dark-mode';

.Callbacks {
  &-section {
    background: var(--Callbacks-bg);
    border: var(--border-width) solid var(--Callbacks-border);
    border-radius: var(--border-radius-lg);
    display: flex;
    flex-direction: column;
    padding: $sm;

    + .Callbacks-section {
      margin: $md 0 $md 0;
    }
  }

  &-label {
    color: var(--Callbacks-color);
    display: flex;
    flex-direction: row;
    font-size: 14px;
    font-weight: var(--font-weight-bold);
    margin-right: $sm;

    &-text {
      padding-right: $xs;
    }

    &-method {
      margin-right: $xs;
    }
  }

  &-expression {
    color: var(--color-text-minimum);
    font-weight: var(--font-weight-bold);
    padding-top: $sm;

    &-parameter {
      background-color: rgba(17, 140, 253, 0.2);
      border-radius: var(--border-radius-lg);
      color: $blue;
      font-size: 12px;
      margin-right: 2px;
      padding: $xs;
    }

    &-connected {
      color: $yellow;
    }
  }

  &-option {
    border-top: var(--border-width) solid var(--Callbacks-border);
    margin-top: $sm;

    &-description {
      font-size: 13px;
      padding-top: $sm;
    }
  }

  &-expand {
    color: var(--color-text-minimum);
    font-size: var(--icon-md);

    &-closed {
      transform: rotate(-90deg);
    }
  }

  &-button {
    background: none;
    border: none;
    display: flex;
    padding: $xs 0 $xs 0;
  }

  &-header {
    display: flex;

    > header {
      margin-right: $xs;
    }

    &-help-icon {
      align-self: center;
      color: var(--color-text-minimum);
      font-size: var(--icon-sm);
    }
  }
}
