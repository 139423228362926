/* stylelint-disable no-descending-specificity */
@import '~@core/styles/vars';

.rm-APIAuth {
  --APIAuth-badge-bg: var(--color-input-background);
  --APIAuth-badge-border: var(--color-border-default);
  --APIAuth-badge-color: var(--color-text-muted);

  // reset some markdown styles
  .rm-Tooltip .markdown-body {
    font-family: var(--font-family);
    font-size: 13px;
    font-weight: var(--font-weight);

    > * {
      margin: 0;
    }

    pre {
      border-radius: var(--border-radius-lg);
    }
  }

  // reset some input group styles
  .InputGroup {
    &-button {
      &:not(:disabled) {
        border-radius: var(--border-radius) 0 0 var(--border-radius);
        margin-bottom: calc(var(--border-width) * -1);
        margin-left: calc(var(--border-width) * -1);
        margin-top: calc(var(--border-width) * -1);

        &:focus,
        &:active {
          border-color: $blue;
          box-shadow: 0 0 0 3px rgba($blue, 0.25);
        }
      }

      &:disabled {
        border-left-color: transparent;
      }

      .icon-chevron-down {
        padding-left: $xs;
      }
    }

    &-oauth-flow-key {
      display: flex;
      font-size: 12px;
      gap: 4px;
      margin-left: 7px;
      margin-top: 3px;
    }

    &-oauth-flow-client {
      display: flex;
      margin-left: 0;
      margin-right: 0;
    }

    // for like, bearer and stuff
    &-prefix {
      align-items: center;
      color: var(--color-input-text);
      display: flex;
      font-family: var(--font-family-mono);
      font-size: 12px;
      margin-left: $sm;
    }

    // project name
    &-badge {
      background: var(--APIAuth-badge-bg);
      border: 1px solid var(--APIAuth-badge-border);
      border-radius: var(--border-radius);
      color: var(--APIAuth-badge-color);
      cursor: help;
      font-family: var(--font-family);
      font-size: 11px;
      font-weight: var(--font-weight);
      margin-left: var(--xs);
      max-width: 80px;
      padding: 3px $xs;

      .InputGroup-GroupItem {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;
      }
    }

    .Dropdown-toggle,
    &-dropdown,
    &-input-parent {
      display: flex;
      flex: 1 1 auto;
    }

    &-dropdown-inputs {
      align-items: center;
      display: flex;
      flex: 1 1 auto;
    }

    &-dropdown-inputs_oauth {
      align-items: stretch;
      flex-direction: column;
    }

    &-input-parent {
      padding-right: $xs;

      &_oauth {
        padding-right: 0;
      }
    }

    &:not(.InputGroup-multiple):not(.InputGroup-oauth) {
      .InputGroup-dropdown-parent .InputGroup-button {
        background: var(--APIAuthInputGroup-bg, var(--gray100));
        border-bottom: 0;
        border-left: 0;
        border-radius: var(--border-radius) 0 0 var(--border-radius);
        border-top: 0;
        margin-bottom: 0;
        margin-left: 0;
        margin-top: 0;

        &:focus,
        &:active {
          border-color: var(--color-border-default);
          box-shadow: none;
          color: var(--APIAuthInputGroup-button-color, var(--color-text-muted));
        }
      }
    }

    // if multiple auth types or oauth
    &-multiple,
    &-oauth {
      display: flex;
      flex-direction: column;
      padding: var(--xs);

      // reset
      &:focus-within {
        background: var(--APIAuthInputGroup-bg, var(--gray100));
        border-color: var(--color-border-default);
        box-shadow: none;
      }

      // the wrapper for all this
      .InputGroup-input-parent {
        background: var(--color-input-background);
        border: 1px solid var(--color-input-border);
        border-radius: var(--border-radius);
        height: $button-sm;
        transition: box-shadow var(--transition-fast) var(--transition-timing);

        &:focus-within {
          border-color: $blue;
          box-shadow: 0 0 0 3px rgba($blue, 0.25);
        }
      }

      .InputGroup-input-parent_oauth {
        background: unset;
        border: unset;
        border-radius: unset;
        height: unset;

        &:focus-within {
          border-color: unset;
          box-shadow: unset;
        }
      }

      // the header el
      .InputGroup-dropdown-parent {
        border-bottom: 1px solid var(--color-border-default);
        display: flex;
        justify-content: space-between;
        padding-bottom: var(--xs);

        .InputGroup-button {
          border: 1px solid var(--color-input-border);
          border-radius: var(--border-radius);
          height: $button-sm;

          &:hover {
            border-color: var(--color-input-border-hover);
          }

          &:active,
          &:focus {
            border-color: var(--color-input-border-active);
            color: inherit;
          }
        }
      }

      .InputGroup-input {
        height: $button-sm;
      }
    }

    &-oauth-flows {
      display: flex;
      flex-direction: column;
    }
  }
}

// Below are some overrides that are specific to the APIAuth component, I'd love to do away with them at some point!
.APIAuth .InputGroup {
  &-GroupItem {
    align-items: center;
    display: inline-flex;
  }

  &-input {
    width: 100%;
  }
}
