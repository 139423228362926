@import '~@core/styles/vars';
@import '~@core/styles/mixins/dark-mode';

.APIHeader {
  &-url-parameter {
    cursor: pointer;
    text-decoration: underline;

    &:hover,
    &:active,
    &:focus {
      text-decoration: none;
    }

    &:focus {
      outline: 0;
    }
  }
}

.reference-sidebar-mobile-button {
  @include dark-mode {
    background: var(--gray15);
  }

  background: var(--gray100);
  border: 1px solid var(--color-border-default);
  border-radius: 0 var(--border-radius) var(--border-radius) 0;
  color: var(--color-text-minimum);
  cursor: pointer;
  display: none;
  font-size: 14px;
  height: $button-sm;
  left: -31px; /* yep. */
  padding: 0;
  position: absolute;
  top: $xs;
  transition: box-shadow var(--transition-fast) var(--transition-timing);
  width: $md;

  &_singleCol {
    border-radius: var(--border-radius);
    left: -27px; // slightly less, so it doesn’t touch the edge @ the smallest width
  }

  &:hover {
    border-color: var(--color-input-border-hover);
  }

  &:active,
  &:focus {
    border-color: var(--color-input-border-active);
    box-shadow: 0 0 0 3px var(--color-input-border-focus);
  }
}

.headline-container {
  border-bottom: 1px solid var(--color-border-default);
  margin-bottom: $sm;
  padding-bottom: $md;
  position: relative;

  &_basic {
    // mirror docs
    border-bottom: 1px solid var(--color-border-default);
    margin-bottom: 30px;
    padding-bottom: 30px;
  }

  h1 {
    margin: 0;
  }

  .excerpt {
    font-size: 18px;
    margin-bottom: 0;
    margin-top: 15px;

    p {
      margin-bottom: 0;
      margin-top: 0;
    }
  }

  &-article-info {
    display: flex;
    margin-top: $sm;

    + .excerpt {
      font-size: 1em;
      margin-top: $sm;
    }
  }

  &-api-method {
    flex: 0 0 auto;
    margin-right: $xs;
    margin-top: 2px; // center align when single row
  }

  &-grid-item {
    margin: 0;
  }

  .ref-QuickNav-mobile-container {
    box-sizing: border-box;
    padding: 15px 20px 10px;
    width: 100%;
  }

  &-article-info-url {
    font-size: 13px;
    word-break: break-all;
  }

  .TagTooltip {
    &-list + .TagTooltip-list {
      @include dark-mode {
        border-color: rgba(white, 0.075);
      }

      border-top: 1px solid rgba(black, 0.075);
      margin-top: $xs;
      padding-top: $xs;
    }

    &-header {
      display: flex;
    }

    &-link {
      flex-direction: column;

      a {
        color: inherit;
      }

      &-description {
        font-size: 12px;
        margin-top: 2px;
        opacity: 0.75;
      }
    }

    &-tag-button {
      display: inline-block;
      margin-left: $xs;
      transform: translateY(-0.4em);
    }
  }
}

.button-container {
  display: flex;
  justify-items: center;

  @media (min-width: $ipad-mini-portrait + 1) {
    flex-direction: column;

    .reference-sidebar-mobile-button {
      display: block;
    }
  }

  @media (min-width: $ipad-pro-10-landscape + 1) {
    .reference-sidebar-mobile-button {
      display: none;
    }
  }
}
