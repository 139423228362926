.RadioGroup {
  $block: &;

  align-items: stretch;
  border: 1px solid var(--color-input-border);
  border-radius: var(--border-radius);
  box-sizing: border-box;
  display: inline-flex;
  flex-direction: column;
  min-width: fit-content;
  overflow: hidden;

  &-radio {
    --Radio-font-size: 12px;

    background: var(--color-input-background);
    border-color: var(--color-input-border);
    border-radius: 0;
    border-style: solid;
    border-width: 0 0 1px;
    height: auto;
    justify-content: flex-start;
    padding: var(--xs) var(--sm);
    position: relative;

    &:last-child {
      border-width: 0;
    }

    // Acts as the active/focus/hover overlay to show during those states.
    > input {
      background: var(--color-bg-hover);
      border: 0;
      border-radius: 0;
      height: 100%;
      left: 0;
      opacity: 0;
      position: absolute;
      top: 0;
      width: 100%;

      &:active,
      &:focus,
      &:hover {
        &:not(:disabled) {
          box-shadow: none;
          opacity: 1;
        }
      }

      &:checked {
        background: var(--color-bg-hover);

        &::before {
          content: none;
        }

        & ~ #{$block}-check-icon {
          translate: 0;
        }
      }
    }

    // Adds spacing next to the radio text label to accommodate the check icon.
    > span:last-child {
      padding-right: 30px
    }
  }

  &-check-icon {
    position: absolute;
    right: var(--sm);
    transition: translate var(--transition-fast) ease-out;
    translate: calc(100% + var(--sm));
  }

  &_full-width {
    display: flex;
  }

  &_disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}
