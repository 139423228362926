@import '@core/styles/vars';
@import '~@core/styles/mixins/dark-mode';

.rm-Flyout {
  --Flyout-background: var(--Header-background, var(--color-bg-page));
  --Flyout-button-color: var(--Header-button-color);
  --Flyout-button-hover: var(--Header-button-hover);
  --Flyout-button-active: var(--Header-button-active);
  --Flyout-button-focus: var(--Header-button-focus);
  --Flyout-width: var(--hub-sidebar-width);

  @include dark-mode {
    --Flyout-background: var(--Header-background, var(--gray15));
  }

  .ThemeContext_line & {
    @include dark-mode {
      --Flyout-button-color: var(--white);
      --Flyout-button-hover: #{rgba(white, 0.1)};
      --Flyout-button-active: #{$blue};
      --Flyout-button-focus: #{rgba($blue, 0.25)};
    }
  }

  .ThemeContext_light & {
    --Flyout-button-color: var(--project-color-inverse);
  }
}

.reference-flyout {
  @include dark-mode {
    --Flyout-background: var(--gray15);
  }
}
