/* stylelint-disable no-descending-specificity */
.RequestBodyEditor {
  background: var(--ParamContainer-bg);
  border: 1px solid var(--color-border-default);
  border-radius: 0;
  box-shadow: var(--ParamContainer-box-shadow);
  margin-bottom: 0;
  overflow: hidden;
  padding: var(--sm);

  &-section {
    margin-top: var(--xs);
  }

  &-Parameter {
    opacity: 0.5;
  }

  &-group {
    $requestbodyeditor-inputs: 162px; // magic number of input + button

    display: grid;
    gap: var(--xs);
    grid-template-columns: minmax(0, calc(100% - $requestbodyeditor-inputs)) 1fr;
  }

  &:not(:first-child) {
    margin-top: -1px;
  }

  &:nth-child(1 of .RequestBodyEditor) {
    border-top-left-radius: var(--border-radius-lg);
    border-top-right-radius: var(--border-radius-lg);
  }

  &:last-child {
    border-bottom-left-radius: var(--border-radius-lg);
    border-bottom-right-radius: var(--border-radius-lg);
  }

  &:only-child {
    border-radius: var(--border-radius-lg);
  }

  .RequestBodyEditor {
    background: var(--ParamContainer-bg-alt);

    &:nth-child(1 of .RequestBodyEditor) {
      margin-top: var(--xs);
    }

    .RequestBodyEditor {
      background: var(--ParamContainer-bg);

      .RequestBodyEditor {
        background: var(--ParamContainer-bg-alt);

        .RequestBodyEditor {
          background: var(--ParamContainer-bg);
        }
      }
    }
  }
}
