@import '~@core/styles/vars';

.Info {
  --APIAuthInfo-color: var(--color-text-minimum);

  &-infoBadge {
    align-items: center;
    background: none;
    border: 0;
    color: var(--APIAuthInfo-color);
    display: inline-flex;
    font-family: var(--font-family);
    font-size: 10px;
    font-weight: var(--font-weight);
    padding: 0;
    text-transform: uppercase;

    :global(.rm-Tooltip) & {
      background: rgba(white, 0.15);
      border: 1px solid rgba(white, 0.25);
      border-radius: var(--border-radius);
      color: var(--white);
      padding-left: 3px;
      padding-right: 3px;
    }

    + .Info-infoBadge {
      margin-left: var(--sm);
    }

    &_tooltip {
      cursor: help;
    }

    &-icon {
      margin-left: 2px;
    }
  }

  &-tooltip {
    text-transform: none;
  }

  &-helpIcon {
    color: var(--APIAuthInfo-color);
    cursor: help;
    font-size: 12px;
    margin-left: var(--xs);

    + :global(.InputGroup-badge) {
      margin-left: var(--xs);
    }
  }

  &-oauth {
    &-flow {
      &-copy-button {
        color: var(--white);
      }

      &-tooltip {
        &-container {
          display: flex;
          flex-direction: column;
        }

        &-label {
          &-container {
            align-items: center;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;
          }

          &-text {
            display: flex;
            flex-direction: column;
            margin-right: var(--sm);
            overflow-wrap: anywhere;
          }
        }
      }
    }
  }
}
