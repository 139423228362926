@import '~@core/styles/mixins/dark-mode';

.Radio {
  --Radio-font-size: 15px;
  --Radio-size: 16px;
  --Radio-disabled-bg: var(--gray80);
  --Radio-disabled-text: var(--gray60);

  @include dark-mode {
    --Radio-disabled-bg: var(--gray30);
    --Radio-disabled-text: var(--gray40);

    color-scheme: light dark;
  }

  $block: &;

  align-items: center;
  cursor: pointer;
  display: flex;
  gap: var(--sm);

  &_card-layout {
    align-items: flex-start;
    flex-direction: column;
    gap: var(--xs);
  }

  &-input {
    appearance: none;
    background-color: var(--color-bg-page);
    border: 1px solid var(--color-input-border);
    border-radius: 50%;
    cursor: pointer;
    display: grid;
    flex: 0 0 var(--Radio-size);
    height: var(--Radio-size);
    margin: 0;
    place-content: center;
    transition: box-shadow var(--transition-slow) var(--transition-timing);
    width: var(--Radio-size);

    &:hover {
      border-color: var(--color-input-border-hover);
    }

    &:checked {
      background-color: var(--blue);

      &::before {
        background-color: var(--white);
        border-radius: 50%;
        content: '';
        display: block;
        height: calc(var(--Radio-size) / 2);
        width: calc(var(--Radio-size) / 2);
      }

      &,
      ~ #{$block}-card {
        border-color: var(--blue);
      }

      ~ #{$block}-label_sm {
        color: var(--color-text-default);
      }

      &:disabled ~ #{$block}-card {
        border-color: var(--Radio-disabled-bg);
      }
    }

    &:disabled {
      background-color: var(--Radio-disabled-bg);
      border-color: var(--Radio-disabled-bg);

      &,
      ~ #{$block}-label,
      ~ #{$block}-card {
        cursor: not-allowed;
      }

      ~ #{$block}-label {
        color: var(--Radio-disabled-text);
      }

      ~ #{$block}-card > * {
        opacity: 0.5;
      }
    }

    &:invalid {
      &,
      ~ #{$block}-card {
        border-color: var(--red);
      }
    }

    &:active,
    &:focus,
    &:focus + #{$block}-card {
      &:not(:disabled) {
        box-shadow: 0 0 0 3px rgba(var(--blue-rgb), 0.25);
        outline: none;

        &:invalid,
        &:invalid ~ #{$block}-card {
          box-shadow: 0 0 0 3px rgba(var(--red-rgb), 0.25);
        }
      }
    }

    &_hidden {
      clip: rect(1px, 1px, 1px, 1px);
      clip-path: inset(50%);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }
  }

  &-card {
    border: 2px solid var(--color-input-border);
    border-radius: var(--border-radius-lg);
    flex: 0 0 auto;
    overflow: clip;
    padding: 2px;
    transition: box-shadow var(--transition-slow) var(--transition-timing);
    width: fit-content;

    > * {
      border-radius: var(--border-radius);
      display: flex;
      overflow: clip;
    }
  }

  &-label {
    color: var(--color-text-default);
    font-size: var(--Radio-font-size);
    font-weight: var(--font-weight);

    &_sm {
      --Radio-font-size: 12px;

      color: var(--color-text-minimum);
    }
  }
}
