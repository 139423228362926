@import '~@core/styles/vars';
@import '~@core/styles/mixins/dark-mode';

.rm-APILogInfo {
  --APILogInfo-bg: var(--gray100);

  @include dark-mode {
    --APILogInfo-bg: var(--gray15);
  }
}
