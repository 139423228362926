@import '@core/styles/vars';
@import '@core/styles/mixins/dark-mode';

.QuickNav {
  --QuickNav-button-bg: var(--color-input-background);
  --QuickNav-button-border: var(--color-input-border);
  --QuickNav-button-border-hover: var(--color-input-border-hover);
  --QuickNav-button-border-active: var(--color-input-border-active);
  --QuickNav-button-border-focus: var(--color-input-border-focus);
  --QuickNav-key-bg: var(--gray100);
  --QuickNav-key-border: var(--color-border-default);
  --QuickNav-key-color: var(--color-text-minimum);
  --QuickNav-item-color: var(--color-text-default);
  --QuickNav-item-border: var(--color-input-border);
  --QuickNav-item-border-hover: var(--color-input-border-hover);
  --QuickNav-item-border-active: var(--color-input-border-active);
  --QuickNav-item-border-focus: var(--color-input-border-focus);

  @include dark-mode {
    --QuickNav-key-bg: var(--gray30);
  }

  &-button {
    align-items: center;
    background-color: var(--QuickNav-button-bg);
    border: 1px solid var(--QuickNav-button-border);
    border-radius: var(--border-radius);
    box-sizing: border-box;
    color: var(--color-text-muted);
    cursor: pointer;
    display: flex;
    font-size: 10px;
    font-weight: var(--font-weight-bold);
    height: 30px;
    justify-content: space-between;
    padding: 1px 6px;
    transition: box-shadow var(--transition-fast) var(--transition-timing);
    width: 100%;

    &:hover {
      border-color: var(--QuickNav-button-border-hover);
    }

    &:focus,
    &:active {
      border-color: var(--QuickNav-button-border-active);
      box-shadow: 0 0 0 3px var(--QuickNav-button-border-focus);
      outline: 0;
    }
  }

  &-key {
    background: var(--QuickNav-key-bg);
    border: 1px solid var(--QuickNav-key-border);
    border-radius: var(--border-radius);
    color: var(--QuickNav-key-color);
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; // Better symbol alignment than SF
    font-size: 11px;
    padding: 1px 3px;
  }

  &-modal {
    max-width: 400px;
    overflow: auto;
    padding: 0;
  }

  &-content {
    box-sizing: border-box;
    overflow-wrap: anywhere;
    padding: var(--sm);
    width: 100%;
  }

  &-modal-search {
    box-shadow:
      0 -20px var(--color-bg-page),
      0 3px 6px -5px rgba(black, 0.25);
    flex: 0 0 auto;
    font-size: 14px;
    left: 10px;
    position: sticky;
    top: 10px;
    transition: none;
    width: calc(100% - 20px);
  }

  &-destination-section {
    list-style-type: none;
    margin: 0;
    padding: var(--sm) 0 0 0;
    width: 100%;

    & + & {
      border-top: 1px solid var(--color-border-default);
      margin-top: var(--sm);
    }

    &-heading {
      color: var(--color-text-minimum);
      font-size: 12px;
      font-weight: var(--font-weight-bold);
      line-height: 20px;
      margin-bottom: var(--xs);
      margin-left: var(--xs);
      margin-top: 0;
      text-transform: uppercase;
    }
  }
}
