/* stylelint-disable-next-line scss/at-import-partial-extension */
@import '~@core/styles/vars.compat';
@import '~@core/styles/mixins/dark-mode';

.APILogInfo {
  border-radius: var(--border-radius-lg) var(--border-radius-lg) 0 0;
  width: 100%;

  &-empty {
    background: var(--APILogInfo-bg);
    border: var(--border-width) solid var(--color-border-default);
    border-radius: var(--border-radius-lg);
    cursor: default;
    display: flex;
    flex-direction: column;
    font-size: 14px;
    font-weight: var(--font-weight-bold);
    overflow: hidden;

    &-top,
    &-bottom {
      padding: 0 var(--sm);
    }

    &-top {
      border: 0 !important;
    }

    &-bottom {
      align-items: center;
      border-top: 1px solid var(--color-border-default);
      color: var(--color-text-minimum);
      display: flex;
      font-size: 12px;
      font-weight: var(--font-weight-normal);
      padding-bottom: var(--xs);
      padding-top: var(--xs);
    }
  }

  &-requests-count {
    color: var(--color-text-muted);
    font-size: 12px;
    font-weight: var(--font-weight-normal);
    margin-right: auto;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &-graph {
    background: var(--APILogInfo-bg);
    border-bottom: var(--border-width) solid var(--color-border-default);
    border-left: var(--border-width) solid var(--color-border-default);
    border-right: var(--border-width) solid var(--color-border-default);
    margin: 0;
  }
}

.GraphTooltip {
  color: var(--color-text-muted);
  font-size: 14px;
  font-weight: var(--font-weight);
  opacity: 1;
  padding: $sm;
  pointer-events: none;
  position: absolute;
  transform: translate(calc(-100% - #{$md}), -$md); // left align
  transition: all var(--transition-fast) var(--transition-timing);
  width: 300px;
  z-index: 1;

  &-empty {
    border: 1px solid var(--color-border-default);
    border-radius: var(--border-radius);
    color: var(--color-text-minimum);
    padding: $md $sm;
    text-align: center;

    &-icon {
      font-size: $icon-md;
      margin-bottom: $sm;
    }
  }

  &-table {
    width: 100%;

    &-title {
      color: var(--color-text-minimum);
      font-size: 16px;
      font-weight: var(--font-weight-bold);
      padding-bottom: $xs;
      text-align: left;
    }

    &-status {
      margin-left: $xs;
    }

    &-count {
      padding-left: $xl;
      text-align: right;
    }

    &-total {
      box-shadow: 0 -1px 0 var(--color-border-default);
      margin-top: $xs;
      padding-top: $sm;
      text-align: right;
    }
  }
}
