@import '~@core/styles/vars';

.APIErrorBoundary {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding: $xs;
  width: 100%;

  &_sm {
    padding: $sm;
  }

  &_md {
    padding: $md;
  }

  &_lg {
    padding: $lg;
  }

  &-icon {
    font-family: 'Apple Color Emoji', 'Segoe UI Emoji';
    font-size: 25px;
    margin-bottom: $sm;
  }

  &-message,
  &-code {
    color: var(--minimumGray, var(--minimum-gray));
    font-size: 14px;
  }

  &-message {
    font-family: var(--font-family);
    line-height: 1.4;
    margin-bottom: $sm;
    margin-top: 0;
    max-width: 250px;
    text-align: center;

    a {
      color: currentcolor;
      text-decoration: underline;

      &:hover,
      &:focus,
      &:active {
        text-decoration: none;
      }
    }
  }

  &-code {
    background: var(--ivory);
    border-radius: var(--border-radius);
    font-family: var(--font-family-mono);
    font-weight: var(--font-weight-bold);
    padding: 2px $xs;
  }
}
