@import '~@core/styles/vars';

.TryIt {
  background-color: var(--TryIt-background);
  border-radius: var(--TryIt-border-radius);
  color: var(--TryIt-color);
  position: relative;

  &:hover,
  &:active,
  &:focus {
    color: var(--TryIt-color);

    &.TryIt_loading {
      color: transparent;
    }
  }

  &:hover {
    background-color: var(--TryIt-background-hover);
  }

  &:active {
    background-color: var(--TryIt-background-active);
  }

  &:focus {
    box-shadow: 0 0 0 3px var(--TryIt-background-focus);
  }

  &:disabled {
    background: var(--TryIt-background-disabled);
  }

  &_loading {
    color: transparent;
  }

  &-spinner {
    $spinner-size: 25px;
    $spinner-width: 3px;

    animation: spinner 1s infinite linear;
    border: $spinner-width solid rgba(black, 0.05);
    border-radius: $spinner-size;
    border-top-color: var(--TryIt-spinner-color);
    bottom: 0;
    box-sizing: border-box;
    height: $spinner-size;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
    width: $spinner-size;

    @keyframes spinner {
      0% {
        transform: rotate(0);
      }

      100% {
        transform: rotate(360deg);
      }
    }
  }
}
