.TypeMenu {
  &Button {
    display: inline-flex;
    gap: 2px;

    &_error {
      border-color: var(--red) !important;
    }
  }
}
