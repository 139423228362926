.DisabledSectionBadge {
  align-items: center;
  aspect-ratio: 1;
  background-color: #000;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: var(--border-radius);
  display: inline-flex;
  font-size: 10px;
  height: 12px;
  justify-content: center;
  line-height: 1;
  padding: 0;

  :global(.IconWrapper:first-child) {
    opacity: 1;
  }
}
