@import '~@core/styles/vars';

.AuthInput {
  --APIAuthInput-bg: var(--color-input-background);
  --APIAuthInput-border: var(--color-input-border);
  --APIAuthInput-color: var(--color-input-text);
  --APIAuthInput-placeholder: var(--color-input-placeholder);

  align-items: center;
  background: var(--APIAuthInput-bg);
  border: 1px solid var(--APIAuthInput-border);
  border-radius: var(--border-radius);
  display: flex;
  flex: 1 1 auto;
  height: var(--button-sm);
  padding-right: var(--xs);
  transition: box-shadow var(--transition-fast) var(--transition-timing);

  &:focus-within {
    border-color: var(--blue);
    box-shadow: 0 0 0 3px rgba($blue, 0.25);
  }

  :global(.Dropdown) {
    width: 100%;
  }

  &-dropdown-inputs {
    align-items: center;
    display: flex;
    flex: 1 1 auto;
  }

  &-input {
    align-items: center;
    background: none;
    border: 0;
    color: var(--APIAuthInput-color);
    display: inline-flex;
    flex: 1 1 auto;
    font-family: var(--font-family-mono);
    font-size: 14px;
    font-weight: var(--font-weight-normal);
    height: var(--lg);
    padding: 0 var(--sm);
    width: 100%;

    &::placeholder {
      color: var(--APIAuthInput-placeholder);
    }

    &:focus {
      outline: 0;
    }
  }

  &-GroupItem {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  }

  &-badge {
    background: var(--APIAuth-badge-bg);
    border: 1px solid var(--APIAuth-badge-border);
    border-radius: var(--border-radius);
    color: var(--APIAuth-badge-color);
    cursor: help;
    font-family: var(--font-family);
    font-size: 9px;
    font-weight: var(--font-weight);
    margin-left: var(--xs);
    max-width: 100;
    padding: 3px var(--xs);

    .AuthInput-GroupItem {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;
    }
  }

  &-prefix {
    align-items: center;
    color: var(--APIAuthInput-color);
    display: flex;
    flex: 0 0 auto;
    font-family: var(--font-family-mono);
    font-size: 12px;
    margin-left: var(--sm);
  }

  &-separator {
    color: var(--gray30);
    font-family: var(--font-family-mono);
    font-size: 12px;
  }
}
