@import '@core/styles/mixins/dark-mode';

.SecuritySchemePicker {
  --SecuritySchemePicker-bg: var(--gray100);

  @include dark-mode {
    --SecuritySchemePicker-bg: var(--gray15);
  }

  background: var(--SecuritySchemePicker-bg);
  border: 1px solid var(--color-border-default);
  border-radius: var(--border-radius);
  margin-bottom: var(--xs);
  padding: var(--sm);
}

.SecuritySchemePickerItem {
  color: var(--color-text-muted);
  font-size: 12px;
  font-weight: var(--font-weight-bold);

  &-info {
    color: var(--color-text-minimum);
    font-size: 12px;
  }
}
