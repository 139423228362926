@import '@core/styles/mixins/dark-mode';

.APIDesignerTab {
  --APIDesignerTab-bg: var(--gray15);
  --APIDesignerTab-bg-hover: var(--gray20);
  --APIDesignerTab-border-rgb: var(--white-rgb);
  --APIDesignerTab-color: var(--white);
  --APIDesignerTab-bg-active: var(--gray10);

  $tab: &;

  background: var(--APIDesignerTab-bg);
  border: 0;
  border-right: 1px solid rgba(var(--APIDesignerTab-border-rgb), 0.1);
  color: var(--APIDesignerTab-color);
  cursor: pointer;
  font-family: var(--font-family);
  font-size: 12px;
  font-weight: var(--font-weight);
  height: var(--button-sm);
  padding: 0 var(--sm);
  position: relative;
  white-space: nowrap;
  z-index: 1;

  // Light theme overrides
  &_light {
    --APIDesignerTab-bg: var(--gray100);
    --APIDesignerTab-bg-hover: var(--gray100);
    --APIDesignerTab-border-rgb: var(--gray10-rgb);
    --APIDesignerTab-color: var(--color-text-minimum);
    --APIDesignerTab-bg-active: white;

    @include dark-mode {
      --APIDesignerTab-color: var(--gray30);
    }
  }

  &:hover:not(#{$tab}_active),
  &:focus:not(#{$tab}_active) {
    background: var(--APIDesignerTab-bg-hover);
  }

  &::after {
    background: var(--gray10);
    content: '';
    display: block;
    height: 1px;
    inset: auto 0 -1px;
    opacity: 0;
    position: absolute;
    width: 100%;
  }

  &_active {
    background: var(--APIDesignerTab-bg-active);
    border-right-color: rgba(var(--APIDesignerTab-border-rgb), 0.15);
    z-index: 2;

    &::after {
      opacity: 1;
    }
  }

  &-chevron {
    opacity: 0;
  }

  &-menu {
    padding-bottom: 0;

    &-languages {
      border-bottom: 1px solid rgba(var(--APIDesignerTab-border-rgb), 0.1);
      border-top: 1px solid rgba(var(--APIDesignerTab-border-rgb), 0.1);
      margin-top: var(--xs);
      max-height: 300px;
      overflow: auto;
      padding: var(--xs) 0;
    }

    &-footer {
      padding: var(--xs) 0;
    }
  }
}
