/* stylelint-disable-next-line scss/at-import-partial-extension */
@import '~@core/styles/vars.compat';
@import '~@core/styles/mixins/dark-mode';

.APIRequest {
  --APIRequest-bg: var(--gray15);
  --APIRequest-bg-rgb: #{$gray15-rgb};
  --APIRequest-border: #{rgba(black, 0.2)};
  --APIRequest-hr: #{rgba(white, 0.05)};
  --APIRequest-hr-shadow: #{rgba(black, 0.1)};
  --APIRequest-title-color: var(--gray80); /* muted on dark themes */
  --APIRequest-shadow: var(--box-shadow-request, 0 1px 2px #{rgba(black, 0.05)}, 0 2px 5px #{rgba(black, 0.02)});

  @include dark-mode {
    --APIRequest-border: var(--color-border-default); /* matching Box */
    --APIRequest-hr-shadow: inset 0 1px 0 #{rgba(white, 0.15)}, inset 0 -1px 2px #{rgba(black, 0.1)};
    --APIRequest-shadow: var(--Box-card-shadow, 0 1px 2px #{rgba(black, 0.1)}, 0 2px 5px #{rgba(black, 0.1)});
  }

  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  max-height: 50vh;
  overflow: hidden;
  padding: 0; /* reset box */

  &-header,
  &-footer {
    align-items: center;
    display: flex;
    flex: 0 0 auto;
    justify-content: space-between;
  }

  &-example {
    &-button {
      background: none;
      border: 0;
      color: var(--color-text-minimum);
    }

    &-button_active {
      @include dark-mode {
        background: var(--color-border-default);
        color: var(--color-text-default);
      }

      background: #47515c;
      color: #fff;

    }

    &-inputgroup {
      background: rgba(var(--white-rgb), 0.05);
      border-radius: var(--border-radius);
      width: auto;

      &::before {
        display: none;
      }
    }

    &-reset {
      background: none;
    }

    &-tab {
      border-bottom: 1px solid var(--APIRequest-hr);
      flex: none;
      flex-direction: row;
      position: relative;

      &-container {
        margin: auto;
        overflow: auto;
        padding: var(--sm);
        position: relative;
        scrollbar-width: none;
      }

      &::before,
      &::after {
        content: '';
        display: block;
        height: 100%;
        inset: 0;
        position: absolute;
        width: 15px;
        z-index: 10;
      }

      &::before {
        background: linear-gradient(90deg, var(--APIRequest-bg), rgba(var(--APIRequest-bg-rgb), 0));
        right: auto;
      }

      &::after {
        background: linear-gradient(90deg, rgba(var(--APIRequest-bg-rgb), 0), var(--APIRequest-bg));
        left: auto;
      }
    }
  }

  &-footer {
    padding: var(--sm);

    &-toggle-button {
      background: none;
      color: var(--white);
      opacity: 0.75;

      &[disabled] {
        cursor: not-allowed;
        opacity: 0.5;
      }
    }
  }

  &-header {
    font-size: 12px;
    font-weight: var(--font-weight-bold);
    overflow: hidden;
    padding: var(--sm) var(--xs);

    &-title {
      color: var(--APIRequest-title-color);
      font-family: var(--font-family);
      font-weight: var(--font-weight-bold);
      text-transform: uppercase;
    }

    &-button {
      max-width: 100%;

      &-text {
        flex: 1 1;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &-badge {
        flex: 0 0 auto;
        margin-left: var(--xs);
      }
    }

    /* example text overflow */
    :global(.Dropdown) {
      overflow: hidden;
    }
  }

  &-instructions {
    &-help {
      height: 20px;
      padding: 0;
      width: 20px;

      &-icon {
        color: var(--chalk);
      }
    }

    &-input {
      background: none;
      border: 0;
      color: var(--green);
      flex: 1 1 auto;
      font-family: inherit;
      font-size: inherit;
      padding: 0;

      &:hover,
      &:active,
      &:focus {
        outline: 0;
      }
    }

    &-body {
      align-items: center;
      background: var(--gray20);
      border: 1px solid rgba(white, 0.1);
      border-radius: var(--border-radius);
      display: flex;
      font-family: var(--font-family-mono);
      font-size: 12px;
      height: 30px;
      margin: $xs 15px;
      padding-left: 25px;
      position: relative;
      transition: box-shadow var(--transition-slow) var(--transition-timing);

      /* stylelint-disable-next-line order/order */
      @include dark-mode {
        background: var(--color-input-background);

        &:hover {
          border-color: var(--color-input-border-hover);
        }
      }

      &:active,
      &:focus {
        border-color: var(--color-input-border-active);
        box-shadow: 0 0 0 3px var(--color-input-border-focus);
      }

      &::before {
        color: var(--white);
        content: '$';
        font-weight: var(--font-weight);
        left: 10px;
        line-height: 30px;
        position: absolute;
      }

      &-button-icon {
        font-size: 14px;
        opacity: 0.6;
      }
    }
  }

  &-libraries {
    &-button {
      background: none;
      border: 0;

      &:hover,
      &:active,
      &:focus {
        outline: 0;
      }
    }
  }

  &-menu {
    font-family: var(--font-family);
    font-weight: var(--font-weight);
    padding: $xs 0;

    &-header,
    &-item {
      padding: $xs $sm;
    }

    &-header {
      color: $chalk;
      font-size: 12px;
    }

    &-item {
      align-items: center;
    }

    &-check {
      color: get(color 'green');
    }
  }

  &-single-library,
  &-webhook-header {
    color: #fff;
    font-size: var(--button-font-size);
    font-weight: var(--font-weight-bold);
    padding-left: var(--sm);
    text-transform: uppercase;
  }

  &-copyButton {
    @include dark-mode {
      color: var(--chalk);

      &:not(:disabled):hover,
      &:not(:disabled):focus-visible {
        color: var(--white);
      }
    }

    border: 0;
    color: var(--chalk);

    &:not(:disabled):hover,
    &:not(:disabled):focus-visible {
      color: var(--white);
      outline: 0;
    }
  }
}

:global .rm-PlaygroundRequest,
:global .rm-RawEditorRequest {
  --RawEditor-bg: var(--gray15);
  --RawEditor-border: rgba(255, 255, 255, 0.1);

  background: var(--APIRequest-bg);
  border: var(--border-width) solid var(--APIRequest-border);
  border-radius: var(--border-radius-lg);
  box-shadow: var(--APIRequest-shadow);
  color-scheme: dark;

  &:target {
    box-shadow: 0 0 0 5px rgba($blue, 0.25);
  }

  .CodeSnippet {
    border-bottom: 1px solid var(--APIRequest-hr);
    box-shadow: inset 0 -1px 0 var(--APIRequest-hr-shadow);
    flex: 1;
    margin-bottom: 0;
    margin-top: 0;

    .CodeEditor,
    .CodeMirror {
      height: 100% !important;
    }

    /* we want the padding here, so macos scrollbars don’t overlap */
    .CodeMirror {
      background: transparent;
    }

    .CodeMirror-gutters {
      background: var(--APIRequest-bg, var(--RawEditor-bg));
    }
  }
}

:global {
  /* stylelint-disable-next-line no-descending-specificity */
  .CodeSnippet {
    overflow: auto;
  }

  /* stylelint-disable-next-line no-descending-specificity */
  .rm-RawEditorRequest {
    .CodeSnippet .CodeMirror,
    .CodeSnippet .CodeMirror-gutters.CodeMirror-gutters {
      background: transparent !important;
    }
  }
}

.RawEditor {
  &-errors {
    background: var(--red);
    border-radius: var(--border-radius-lg);
    color: var(--white);
    font-family: var(--font-family);
    font-size: 12px;
    font-weight: var(--font-weight-bold);
    padding: 2px 6px;
    text-transform: uppercase;
  }

  &-footer {
    padding: var(--xs);
  }

  &-input {
    background: var(--RawEditor-bg);
    border: 1px solid var(--RawEditor-border);
    font-size: 12px;
    margin: var(--sm) var(--sm) 0 var(--sm);
    padding-top: var(--sm);
    resize: none;
    width: inherit;

    &:hover {
      border-color: rgba(white, 0.15);
    }

    &:focus,
    &:focus-within {
      border-color: var(--blue);
    }

    /*
      Need to use this hacky double selector to override
      .CodeMirror height: auto on Line 197
    */
    :global(.CodeMirror.CodeMirror) {
      height: 100px !important;
    }
  }

  &-header {
    padding-right: var(--sm);
  }

  &-reset {
    margin: var(--sm);
  }
}

.WebhookRequest {
  background-color: var(--gray15);
  border: 1px solid var(--color-border-default);
  border-radius: var(--border-radius-lg);
  color: var(--gray80);

  &-empty {
    border-top: 1px solid var(--color-border-default);
    font-size: 14px;
    padding: var(--md);
  }
}
