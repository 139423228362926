@import '~@core/styles/vars';

.ChatGPT {
  /* stylelint-disable scss/operator-no-unspaced */
  --Owlbot-color-1: var(--yellow);
  --Owlbot-color-2: var(--green);
  --Owlbot-color-3: var(--red);
  --Owlbot-color-4: var(--green);
  --Owlbot-color-5: var(--red);
  --Owlbot-scale: 3.25em;
  --Owlbot-orb-shadow: #{inset 0 -1px 2em rgba(black, 0.1), inset 0 1px 0 rgba(white, 0.3), 0 1em 3em rgba(black, 0.1)};

  /* markdown vars */
  --md-code-background: var(--gray10);
  --md-code-text: var(--color-text-default);
  /* stylelint-enable scss/operator-no-unspaced */

  $block: &;
  $chatgpt-height: $button-sm;

  &-form {
    align-items: center;
    border: 0;
    border-radius: $chatgpt-height;
    display: inline-flex;
    gap: $xs;
    padding: 2px 7px;
    transition: box-shadow var(--transition-slow) var(--transition-timing);

    &_disabled {
      --color-input-placeholder: transparent;

      #{$block}-input {
        color: transparent;
      }

      #{$block}-orb {
        display: none;
      }
    }
  }

  &-input {
    background: transparent;
    border: 0;
    box-sizing: border-box;
    color: var(--Owlbot-ai-color, var(--color-input-text));
    flex: 1;
    font-family: inherit;
    font-size: 12px;
    line-height: 1.4;
    min-height: $chatgpt-height;
    padding: var(--xs);
    transition: width var(--transition-slow) var(--transition-timing);

    &_disabled {
      animation: pulse 2.5s infinite linear;
      color: var(--color-text-minimum);
      cursor: wait;

      @keyframes pulse {
        0% {
          opacity: 1;
        }

        50% {
          opacity: 0.25;
        }

        100% {
          opacity: 1;
        }
      }
    }

    &-kbd {
      background: rgba(white, 0.075);
      border: 1px solid rgba(white, 0.1);
      border-radius: var(--border-radius);
      font-family: var(--font-family-mono);
      font-size: 11px;
      padding: 0 2px;
    }

    &-ellipsis {
      @keyframes ellipsis1 {
        0%,
        24% {
          opacity: 0;
        }

        25%,
        100% {
          opacity: 1;
        }
      }

      @keyframes ellipsis2 {
        0%,
        49% {
          opacity: 0;
        }

        50%,
        100% {
          opacity: 1;
        }
      }

      @keyframes ellipsis3 {
        0%,
        74% {
          opacity: 0;
        }

        75%,
        100% {
          opacity: 1;
        }
      }

      &:nth-child(1) {
        animation: ellipsis1 3s infinite linear;
      }

      &:nth-child(2) {
        animation: ellipsis2 3s infinite linear;
      }

      &:nth-child(3) {
        animation: ellipsis3 3s infinite linear;
      }
    }
  }

  &-orb {
    align-items: center;
    display: flex;
    font-size: 1.5px;
    height: 100%;
    justify-content: center;
    overflow: hidden;
    position: relative;
    transition: box-shadow var(--transition-slow);
    width: 100%;

    @keyframes spin {
      0% {
        transform: none;
      }

      100% {
        transform: rotate(360deg);
      }
    }

    &-parent {
      border: 1px solid rgba(black, 0.2);
      border-radius: 50%;
      border-top-color: rgba(black, 0.05);
      box-shadow: var(--Owlbot-orb-shadow);
      height: $chatgpt-height - $sm;
      width: $chatgpt-height - $sm;

      &_loading {
        #{$block}-orb {
          animation: spin 1s linear infinite;

          &-child {
            filter: blur(1.8em) contrast(2) saturate(1.2);
            transform: scale(1.1);
          }

          &-text {
            opacity: 0;
          }
        }
      }
    }

    &-child {
      border-bottom: var(--Owlbot-scale) solid var(--Owlbot-color-4);
      border-left: var(--Owlbot-scale) solid transparent;
      border-radius: var(--Owlbot-scale);
      border-right: var(--Owlbot-scale) solid transparent;
      border-top: var(--Owlbot-scale) solid var(--Owlbot-color-1);
      filter: blur(2em) contrast(2);
      height: 0;
      position: relative;
      transition: var(--transition-slow);
      width: 0;

      &::before,
      &::after {
        animation: spin linear infinite;
        border-radius: var(--Owlbot-scale);
        content: '';
        height: 0;
        inset: calc(var(--Owlbot-scale) * -1) 0 0 calc(var(--Owlbot-scale) * -1);
        position: absolute;
        width: 0;
      }

      &::after {
        animation-duration: 6s;
        border-bottom: var(--Owlbot-scale) solid var(--Owlbot-color-5);
        border-left: var(--Owlbot-scale) solid transparent;
        border-right: var(--Owlbot-scale) solid transparent;
        border-top: var(--Owlbot-scale) solid var(--Owlbot-color-2);
      }

      &::before {
        animation-duration: 8s;
        border-bottom: var(--Owlbot-scale) solid var(--Owlbot-color-3);
        border-left: var(--Owlbot-scale) solid transparent;
        border-right: var(--Owlbot-scale) solid transparent;
        border-top: var(--Owlbot-scale) solid var(--Owlbot-color-1);
        mix-blend-mode: exclusion;
      }
    }

    &-text {
      align-items: center;
      color: var(--color-text-default);
      display: flex;
      font-size: 9px;
      font-weight: 700;
      inset: 0;
      justify-content: center;
      line-height: 20px;
      margin: auto;
      opacity: 0.5;
      position: absolute;
      transition: opacity 1s var(--transition-timing);
    }
  }

  &-answer {
    color: currentcolor;
    color-scheme: dark;
    width: 100%;

    &:empty {
      display: none;
    }

    &-text {
      --markdown-text: var(--Owlbot-ai-color);

      border-top: 1px solid rgba(white, 0.1);
      display: flex;
      flex-direction: column;
      gap: var(--sm);
      padding: var(--sm) var(--sm) var(--md);

      &-rdmd {
        font-size: 13px;
        font-weight: var(--font-weight-normal);
        line-height: 1.4;
        margin-bottom: var(--xs);
        max-width: 100%;
      }
    }

    &-voting {
      border-top: 1px solid rgba(white, 0.1);
      margin: 0 var(--sm) var(--sm);
      padding-top: var(--sm);
    }

    &-button {
      appearance: none;
      background: transparent;
      border: 0;
      padding: 0;
    }

    &-clipboard {
      color: var(--color-text-muted);
      height: auto;
      width: auto;

      &:not(:disabled):hover,
      &:not(:disabled):focus-visible {
        color: var(--color-text-muted);
      }
    }

    button.ChatGPT-answer-btn {
      border: 0;
      box-shadow: none;

      &:hover,
      &:active,
      &:focus {
        border: 0;
        box-shadow: none;
      }
    }

    &-thumb {
      transition: var(--transition-slow) var(--transition-timing);

      &-selected {
        animation: pop var(--transition-slow) var(--transition-timing);
      }

      @keyframes pop {
        0% {
          transform: scale(1);
        }

        50% {
          transform: scale(1.1);
        }

        100% {
          transform: scale(1);
        }
      }
    }

    // @readme/markdown overrides
    /* stylelint-disable-next-line order/order */
    --markdown-font: var(--font-family);
    --markdown-font-size: 15px;
    --markdown-line-height: 1.5;
    --markdown-title-font: var(--font-family);
    --markdown-title-weight: var(--font-weight-bold);
    --table-edges: #3a4348;
    --table-head-text: var(--color-text-default);
    --table-text: var(--color-text-default);
    --table-row: var(--color-bg-page);
    --table-head: var(--gray15);
    --table-stripe: var(--gray15);
    --md-code-background: var(--gray15);
    --md-code-tabs: var(--gray20);
    --md-code-text: var(--color-text-default);

    .markdown-body {
      blockquote:not(.callout) {
        border-color: var(--gray20);
        color: var(--color-text-minimum);
      }

      .lightbox.open {
        background-color: rgba(var(--color-bg-page-rgb), 0.95);
      }

      hr {
        border-bottom: 1px solid var(--color-border-default);
      }

      .task-list-item input[type='checkbox'] {
        color-scheme: dark;
      }
    }

    a,
    a:hover {
      color: var(--color-text-default);
    }

    .callout.callout_default {
      --background: rgba(var(--gray100-rgb), 0.05);
    }

    .callout.callout_info {
      --background: rgba(var(--blue-rgb), 0.05);
    }

    .callout.callout_ok,
    .callout.callout_okay,
    .callout.callout_success {
      --background: rgba(var(--green-rgb), 0.05);
    }

    .callout.callout_warn,
    .callout.callout_warning {
      --background: rgba(var(--yellow-rgb), 0.05);
    }

    .callout.callout_err,
    .callout.callout_error {
      --background: rgba(var(--red-rgb), 0.05);
    }
  }

  &-prev {
    gap: var(--xs);
  }

  &-sources {
    flex-wrap: wrap;
  }

  &-source {
    align-items: center;
    border: 1px solid rgba(white, 0.1);
    border-radius: 20px;
    color: white;
    display: inline-flex;
    font-size: 12px;
    gap: 5px;
    height: 20px;
    overflow: hidden;
    padding: 0 7px;
    transition: box-shadow var(--transition-slow) var(--transition-timing);
    white-space: nowrap;

    &:hover,
    &:active,
    &:focus {
      border-color: rgba(white, 0.2);
      color: white;
    }

    &:active,
    &:focus {
      box-shadow: 0 0 0 3px rgba(white, 0.1);
    }

    &-text {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &-icon {
      opacity: 0.5;
    }
  }

  &-support {
    color: var(--Owlbot-support);
    font-size: 11px;
  }
}
