.SchemaItems {
  background: var(--APIResponseSchemaPicker-bg);
  border-left: 1px solid var(--APIResponseSchemaPicker-border);
  border-right: 1px solid var(--APIResponseSchemaPicker-border);
  padding: 0 var(--sm) var(--sm);

  :global {
    .rm-APISectionHeader {
      margin-top: 0;
    }

    .rm-APISchema {
      border-bottom: 0;
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }
}
