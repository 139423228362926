@import '~@core/styles/vars';

.APIAuth {
  --APIAuth-login-color: var(--color-text-minimum);
  --APIAuth-login-color-hover: var(--color-text-minimum-hover);
  --APIAuth-tabs: var(--color-text-minimum);
  --APIAuth-tabs-hover: var(--color-text-minimum-hover);

  display: flex;
  overflow: visible;
  padding: 0;

  // ensure the group dropdown renders above siblings
  position: relative;
  z-index: 7; // uh, some magic number that brings it above codemirror

  &-col {
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: var(--xs);
    justify-content: flex-start;
    padding: var(--sm);

    + .APIAuth-col {
      border-left: 1px solid var(--color-border-default);
    }
  }

  // all the header things
  &-heading {
    color: var(--gray30);
    font-family: var(--font-family);
    font-size: 12px;
    font-weight: var(--font-weight-bold);
    text-transform: uppercase;
  }

  &-form {
    display: flex;
    flex-direction: column;
    gap: var(--sm);
  }

  &-hr {
    border: 0;
    border-top: 1px solid var(--color-border-default);
    width: 100%;
  }

  &-input-heading {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  &-badge {
    border: 1px solid var(--color-border-default);
    border-radius: var(--border-radius);
    color: var(--color-text-default);
    font-size: 12px;
    font-weight: var(--font-weight-bold);
    padding: 3px 6px;
    text-transform: uppercase;
  }

  &-button {
    background-color: var(--color-link-primary);
    color: var(--color-link-text);

    &:hover {
      background-color: var(--color-link-primary-darken-10);
      color: var(--color-link-text);
    }

    &:active {
      background-color: var(--color-link-primary-darken-20);
    }

    &:focus {
      box-shadow: 0 0 0 3px var(--color-link-primary-alpha-25);
    }
  }

  &-oauthButton {
    border: 1px solid transparent;
    border-radius: var(--border-radius);
    color: var(--color-primary-inverse);
    font-family: var(--font-family);
    font-size: 12px;
    font-weight: var(--font-weight-bold);
    padding: 3px 6px;
    text-decoration: none;
    transition: box-shadow var(--transition-fast) var(--transition-timing);

    &:focus {
      outline: 0;
    }

    // for the authorize state
    &_authorize {
      background: var(--color-primary, $blue);

      &:hover {
        background: var(--color-primary-darken-10, #{darken($blue, 10%)});
      }

      &:active {
        background: var(--color-primary-darken-20, #{darken($blue, 20%)});
      }

      &:active,
      &:focus {
        box-shadow: 0 0 0 3px var(--color-primary-alpha-25, #{rgba($blue, 0.25)});
      }
    }

    // for the "log out" button state
    &_logOut {
      color: $red;
      transition: box-shadow var(--transition-speed) var(--transition-timing);

      &:active,
      &:focus {
        border-color: $red;
        box-shadow: 0 0 0 3px rgba($red, 0.25);
      }
    }
  }

  // log in stuff
  &-login {
    color: var(--APIAuth-login-color);
    font-size: 14px;
    margin-top: var(--sm);

    &-icon {
      opacity: 0.5;
    }

    &-link {
      align-items: center;
      color: currentcolor;
      display: flex;
      text-decoration: none;

      &:hover,
      &:active,
      &:focus {
        color: var(--APIAuth-login-color-hover);
      }
    }
  }

  // menus
  &-menu {
    &-header {
      font-family: var(--font-family);
    }

    &-item {
      align-items: center;
      display: flex;
      flex-direction: column;

      :global(.markdown-body) {
        color: var(--color-text-minimum);
        font-size: 12px;
        font-weight: var(--font-weight);

        > * {
          margin: 0;
        }
      }

      &-externalIcon {
        margin-left: 2px;
      }
    }

    &-subtitle {
      color: var(--color-text-minimum);
      font-size: 12px;
      font-weight: var(--font-weight);
    }

    &_loggedOut {
      &-message,
      &-buttonparent {
        padding: 10px 15px;
      }

      &-message {
        border-bottom: 1px solid var(--gray90);
        color: var(--gray30);
      }

      &-buttonparent {
        display: flex;
        justify-content: flex-end;

        a {
          background: var(--color-primary);
          border-radius: var(--border-radius);
          color: var(--color-primary-inverse);
          padding: 5px 7px;
          text-decoration: none;
        }
      }
    }
  }

  &-tab {
    align-items: center;
    background: transparent;
    border: 0;
    border-radius: var(--border-radius);
    color: var(--APIAuth-tabs);
    display: flex;
    font-size: 14px;
    justify-content: space-between;
    padding: 4px 0;
    text-align: left;
    transition: color var(--transition-fast) var(--transition-timing);
    width: 100%;

    &:not(.APIAuth-tab_active) {
      cursor: pointer;
    }

    &:focus {
      box-shadow: 0 0 0 3px var(--color-link-primary-alpha-25);
      outline: 0;
    }

    &:hover {
      color: var(--APIAuth-tabs-hover);
    }

    &_active,
    &_active:hover {
      color: var(--color-link-primary);
    }

    &-subtitle {
      font-size: 12px;
      opacity: 0.75;
    }
  }
}
