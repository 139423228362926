@import '@core/styles/vars';

.destination-item {
  align-items: center;
  color: var(--slate);
  display: flex;
  font-size: 14px;

  & + & {
    margin-top: 2px;
  }

  &-anchor {
    align-items: center;
    border: 1px solid transparent;
    border-radius: var(--border-radius);
    color: var(--QuickNav-item-color);
    display: flex;
    font-weight: var(--font-weight);
    height: 100%;
    justify-content: space-between;
    padding: var(--xs);
    text-decoration: inherit;
    transition: box-shadow var(--transition-fast) var(--transition-timing);
    width: 100%;
    word-break: break-word;

    &:hover {
      border-color: var(--QuickNav-item-border-hover);
      color: var(--QuickNav-item-color);
    }

    &:active,
    &:focus {
      border: 1px solid var(--QuickNav-item-border-active);
      box-shadow: 0 0 0 3px var(--QuickNav-item-border-focus);
      color: var(--QuickNav-item-color);
      outline: 0;
    }

    &-row-container {
      align-items: center;
      display: flex;
      justify-content: space-between;
      width: 100%;

      &-method {
        margin: 0 0 0 $xs;
        min-width: 40px;
      }
    }
  }
}
