@import '~@core/styles/vars';
@import '~@core/styles/mixins/dark-mode';

.MyDevelopersSkeleton {
  --MyDevelopers-bg: var(--gray100);

  @include dark-mode {
    --MyDevelopers-bg: var(--gray10);

    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      --MyDevelopers-bg: rgb(36, 46, 52);
    }
  }

  background: var(--MyDevelopers-bg);
  min-height: 100vh;
  width: 100%;

  &-title,
  &-title-skeleton {
    flex-shrink: 0;
  }

  &-header {
    background: var(--MyDevelopers-bg);
    margin-bottom: 15px;
    position: sticky;
    top: 0;
  }

  &-layout {
    align-items: flex-start;
    display: grid;
    gap: 2rem;
    grid-template-columns: minmax(250px, 250px) minmax(0, 1fr);
    margin: 1rem 0;
    padding-bottom: 1rem;
    position: relative;
  }

  &-end-skeleton {
    margin-left: auto;
  }
}

.FiltersSkeleton {
  &-search {
    margin: var(--sm) 0;
  }

  &-header {
    color: var(--color-text-minimum);
    font-size: 12px;
    font-weight: var(--font-weight);
    padding-bottom: var(--xs);
    padding-top: var(--xs);
  }

  &-group {
    border-bottom: 1px solid var(--color-border-default);
    margin: var(--xs) 0 1rem;
    padding: 0 0 1rem;

    &:last-child {
      border-bottom: 0;
    }
  }

  &-list {
    list-style: none;
    margin: var(--xs) 0 0;
    padding: 0;

    li {
      margin-bottom: var(--sm);
    }
  }

  &-list-more {
    margin-left: 27px;

    &-icon {
      background: var(--color-skeleton);
      border: 0;
      border-radius: var(--border-radius);
      color: white;
      width: 20px;
    }
  }
}

.TableSkeleton {
  position: relative;
  width: 100%;

  table {
    border-collapse: separate;
    border-spacing: 0;
    width: 100%;
  }

  th {
    color: var(--color-text-minimum);
    font-size: 12px;
    font-weight: var(--font-weight-bold);
    line-height: 1.6;
    text-align: left;
    text-transform: uppercase;
    white-space: nowrap;
  }

  th,
  td {
    height: 20px;
    padding: var(--sm);
  }

  tr th {
    box-shadow: inset 0 -1px 0 var(--color-border-default);
  }

  th:last-child {
    padding-right: 30px;
  }

  tr + :global(tr) :global(td) {
    border-top: 1px solid var(--color-border-default);
  }

  &-columnSelector-btn {
    position: absolute;
    right: 0;
    text-transform: none;
    top: var(--sm);
    z-index: 2;
  }
}
