.MyDevelopersHero {
    &-container {
        height: 100%;
        position: relative;
        width: 100%;
      }
    
      &-logs-container {
        display: flex;
        flex-direction: column;
        height: 150px;
        justify-content: flex-end;
        margin: 0 auto;
        perspective: 1000px;
        position: relative;
        width: 520px;
        margin-bottom: -75px;
      }
    
      &-api-log {
        backdrop-filter: blur(8px);
        background: rgba(255, 255, 255, 0.05);
        border: 1px solid rgba(255, 255, 255, 0.1);
        border-radius: var(--xs);
        font-size: 14px;
        height: 32px;
        left: 50%;
        margin-bottom: 16px;
        padding: 2px 15px;
        position: absolute;
        transform-origin: center;
        transition: all 0.8s cubic-bezier(0.34, 1.56, 0.64, 1), 
                    box-shadow 0.3s ease-out,
                    filter 0.3s ease-out;
        width: 100%;
        will-change: transform, opacity;
    
        &:nth-child(1) {
          filter: blur(4px);
          opacity: 0;
          top: -48px;
          transform: translateX(-50%) translateY(1rem) scale(0.95) translateZ(-50px);
        }
    
        &:nth-child(2) {
          filter: blur(1px);
          opacity: 0;
          top: 0;
          transform: translateX(-50%) translateY(0) scale(0.97) translateZ(-25px);
        }
    
        &:nth-child(3) {
          box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
          filter: blur(0);
          opacity: 1;
          top: 0;
          transform: translateX(-50%) translateY(0) scale(1) translateZ(0);
        
          overflow: hidden;
          &::after {
            content: '';
            position: absolute;
            inset: -1px;
            pointer-events: none;
            opacity: 0;
            background: linear-gradient(
              to right,
              transparent 0%,
              rgba(var(--green100-rgb), 0.05) 50%,
              transparent 100%
            );
            transition: opacity 0.3s ease;
          }
    
          &:hover {
            box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
            cursor: pointer;
            filter: brightness(1.1);
            transform: translateX(-50%) translateY(-4px) scale(1.02) translateZ(10px);
            border: 1px solid rgba(255, 255, 255, 0.2);
            background: rgba(255, 255, 255, 0.10);
    
            &::after {
              opacity: 1;
              animation: shimmer 2s infinite linear;
            }
          }
        }
    
        &:nth-child(4) {
          filter: blur(2px);
          opacity: 0.25;
          top: 3rem;
          transform: translateX(-50%) translateY(0) scale(0.97) translateZ(-25px);
        }
    
        &:nth-child(5) {
          filter: blur(4px);
          opacity: 0.15;
          top: 6rem;
          transform: translateX(-50%) translateY(-1rem) scale(0.95) translateZ(-50px);
        }
    
        &.-entering {
          animation: slideIn 0.3s ease-out forwards;
        }
    
        &.-paused {
          animation-play-state: paused !important;
          
          .SetupApiLogs-api-log-content {
            animation-play-state: paused !important;
          }
        }
    
        &.apiLog {
          animation: slideFromTop 0.3s ease-out;
          margin-bottom: 1rem;
    
          &:not(:first-child) {
            margin-top: 1rem;
          }
        }
    
        &-content {
          height: 100%;
        }
    
      }
    
      &-status {
        align-items: center;
        display: flex;
        min-width: 45px;
        width: 45px;
      }
    
      &-method {
        align-items: center;
        display: flex;
        
        width: 45px;
      }
    
      &-api-path {
        align-items: center;
        display: flex;
        
        overflow: hidden;
        padding: 0 var(--xs);
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 90px;
        opacity: 0.8;
      }
    
      &-email {
        align-items: center;
        display: flex;
        
        overflow: hidden;
        padding: 0 var(--xs);
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 140px;
        opacity: 0.8;
      }
    
      &-api-key {
        align-items: center;
        display: flex;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 60px;
        opacity: 0.8;
      }
    
      &-chart-container {
        align-items: flex-end;
        display: flex;
        gap: 2px;
        height: 40px;
        justify-content: center;
        padding: 0 1rem;
        perspective: 1000px;
        width: 500px;
      }
    
      &-chart-bar {
        background-color: rgba(255, 255, 255, 0.1);
        border-radius: 10px;
        cursor: pointer;
        position: relative;
        transform-origin: bottom center;
        transition: all 0.4s cubic-bezier(0.34, 1.56, 0.64, 1);
        width: 4px;
        
        &::before {
          background: linear-gradient(
            to bottom,
            rgba(0, 0, 0, 0.01) 0%,
            rgba(0, 0, 0, .2) 100%
          );
          border-radius: inherit;
          content: '';
          inset: 0;
          position: absolute;
        }
    
        &:hover {
          transform: scaleY(1.02);
        }
      }
    
      &-counters {
        display: flex;
        gap: 75px;
        justify-content: space-between;
        width: 500px;
      }
    
      &-counter {
        align-items: center;
        color: var(--gray50);
        display: flex;
        flex-direction: row;
        font-size: 12px;
        font-size: 0.875rem;
        gap: var(--xs);
        opacity: 0.8;
        text-align: center;
        transition: all 0.3s ease-out;
        
        &-value {
          color: white;
          font-feature-settings: 'tnum'; // Enable tabular numbers
          font-size: 12px;
          font-weight: 600;
          min-width: 4ch; // Reserve space for at least 4 characters
          text-align: left;
          transition: all 0.3s cubic-bezier(0.34, 1.56, 0.64, 1);
          
          &.updating {
            animation: counterUpdate 0.4s cubic-bezier(0.34, 1.56, 0.64, 1);
          }
        }
    
      }
    
      @keyframes barEnter {
        from {
          opacity: 0.5;
          transform: scaleY(0.3);
        }
    
        to {
          opacity: 1;
          transform: scaleY(1);
        }
      }
    
      @keyframes counterUpdate {
        0% {
          opacity: 0;
          transform: translateY(-10px);
        }
    
        100% {
          opacity: 1;
          transform: translateY(0);
        }
      }
    
      &-tooltip-content {
        font-size: 14px;
        line-height: 1.4;
      }
    
      &-tooltip-all {
        width: fit-content;
        text-decoration: none;
        margin-top: var(--xs);
      }
    
      &-tooltip-row {
        align-items: center;
        display: flex;
        gap: var(--xs);
        
        &:not(:last-child) {
          margin-bottom: 8px;
        }
      }
    
      &-tooltip-label {
        color: var(--gray50);
        min-width: 65px;
      }
    
      &-tooltip-value {
        color: rgba(255, 255, 255, 0.8);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    
      &-tooltip-user {
        min-width: 230px;
        padding: var(--sm);
        display: flex;
        flex-direction: column;
        gap: var(--md);
    
        &-header {
          display: flex;
          gap: var(--sm);
          align-items: center;
          flex-direction: column;
          color: rgba(255, 255, 255, 0.8);
        }
    
        &-info {
          display: flex;
          flex-direction: column;
          gap: var(--xs);
          align-items: center;
        }
    
        &-name {
          font-weight: 600;
          font-size: var(--font-size-md);
        }
    
        &-active {
          color: rgba(var(--gray50-rgb), 0.8);
          font-size: var(--font-size-sm);
        }
    
        &-email-button {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: var(--xs);
          opacity: 0.8;
        }
      }
    
      &-tooltip-section {
        display: flex;
        flex-direction: column;
        gap: var(--sm);
    
        &-title {
          font-weight: 500;
          color: var(--gray50);
        }
      }
    
      &-tooltip-metrics {
        display: flex;
        flex-direction: column;
        gap: var(--sm);
      }
    
      &-tooltip-metric {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
    
        &-label {
          display: flex;
          align-items: center;
          gap: var(--xs);
          color: rgba(255, 255, 255, 0.8);
          font-size: var(--font-size-sm);
        }
    
        &-value {
          color: var(--gray50);
          font-weight: 600;
        }
      }
    
      &-tooltip-endpoints,
      &-tooltip-errors {
        display: flex;
        flex-direction: column;
        gap: var(--sm);
      }
    
      &-tooltip-endpoint,
      &-tooltip-error {
        color: rgba(255, 255, 255, 0.8);
        display: flex;
        align-items: center;
        gap: var(--xs);
        font-size: var(--font-size-sm);
    
        &-status {
          padding: 0 !important;
        }
    
        &-percent {
          margin-left: auto;
          color: var(--gray50);
        }
      }
    
      &-tooltip-user-avatar {
        width: 40px;
        height: 40px;
        border-radius: 10px;
        border: 1px solid rgba(255, 255, 255, 0.1);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        color: rgba(255, 255, 255, 0.5);  
      }
    }
    
  
    .success {
      background-color: rgba(var(--green-rgb),.8);
    }
    
    .error {
      background-color: rgba(var(--red-rgb),.8);
    }
    
    @keyframes slideFromTop {
      0% {
        filter: blur(4px);
        opacity: 0;
        transform: translateX(-50%) translateY(100%) scale(0.95) rotate(2deg) translateZ(-50px);
      }
      
      50% {
        filter: blur(1px);
        opacity: 0.7;
        transform: translateX(-50%) translateY(-20%) scale(1.03) rotate(-1deg) translateZ(20px);
      }
    
      75% {
        filter: blur(0);
        opacity: 0.9;
        transform: translateX(-50%) translateY(5%) scale(1.01) rotate(0.5deg) translateZ(5px);
      }
    
      100% {
        filter: blur(0);
        opacity: 1;
        transform: translateX(-50%) translateY(0) scale(1) rotate(0) translateZ(0);
      }
    }
    
    @keyframes fadeUp {
      from {
        opacity: 0;
        transform: translateY(10px);
      }
    
      to {
        opacity: 1;
        transform: translateY(0);
      }
    }
    
    @keyframes slideFromBottom {
      from {
        opacity: 0;
        transform: translateY(-100%);
      }
    
      to {
        opacity: 1;
        transform: translateY(0);
      }
    }
    
    @keyframes fadeContent {
      from {
        opacity: 0;
        transform: translateX(-10px);
      }
    
      to {
        opacity: 1;
        transform: translateX(0);
      }
    }
    
    @keyframes barSlideUp {
      0% {
        opacity: 0;
        transform: scaleY(0);
      }
      
      100% {
        opacity: 0.7;
        transform: scaleY(1);
      }
    }
    
    :global {
      .tippy-box[data-theme~="custom-tooltip"] {
        background: rgba(255, 255, 255, 0.05);
        backdrop-filter: blur(20px);
        -webkit-backdrop-filter: blur(20px);
      }
    }
    
    @keyframes pulseBar {
      0% {
        transform: scaleY(0.8);
      }
    
      50% {
        transform: scaleY(1.1);
      }
    
      100% {
        transform: scaleY(1);
      }
    }
    
    @keyframes shimmer {
      0% {
        transform: translateX(-100%);
      }
      100% {
        transform: translateX(100%);
      }
    }
    