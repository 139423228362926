.SetupLanguagePicker {
    --SetupLanguagePicker-sub-width: 240px;

    &-container {
      padding: 30px 0;
      text-align: center;
    }
  
    &-action-slot {
        width: var(--SetupLanguagePicker-sub-width);
        margin-top: var(--md);
    }
    
    &-title {
      max-width: 80%;
      text-wrap: balance;
    }

    &-description {
      font-weight: var(--font-weight);
    }

    &-description, &-legend {
        color: var(--color-text-muted);
        font-size: 16px;
        margin: 0;
        opacity: 0.5;
        width: 400px;
    }
  
    &-separator {
      background-color: var(--color-border-default);
      height: 1px;
      margin: var(--xs) 0;
      width: var(--SetupLanguagePicker-sub-width);
    }
  
    &-fieldset {
      border: 0; 
      text-align: center;
    }
  
    &-legend {
      font-weight: var(--font-weight-normal);
    }
  
    &-languagePicker {
      padding: var(--sm) 0;
      width: fit-content;
    }
  }
  