@import '@core/styles/mixins/dark-mode';
@import '@core/styles/vars.compat';

.ResponseExampleEditor {
  background: var(--gray100);
  border: 1px solid var(--color-border-default);
  border-radius: 7.5px;
  overflow: hidden;
  width: 100%;

  &_disabled {
    opacity: 0.7;
  }

  &-header {
    border-bottom: 1px solid var(--color-border-default);
    overflow: auto hidden;

    &-tabs {
      flex: 0 1 auto;
    }

    &-add {
      align-items: center;
      background: inherit;
      border: 0;
      border-left: 1px solid rgba(white, 0.1);
      border-right: 1px solid transparent;
      cursor: pointer;
      display: flex;
      flex: 0 0 var(--button-sm);
      height: var(--button-sm);
      justify-content: center;
      margin-left: -1px;
      position: sticky;
      right: 0;
      width: var(--button-sm);
      z-index: 2;

      &:hover,
      &:focus,
      &:active {
        background: var(--gray90);
        border-right-color: rgba(white, 0.1);
      }
    }
  }

  &-code {
    background: white;
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    width: 100%;

    // overriding some codemirror defaults for accessibility
    :global(.CodeEditor),
    :global(.CodeMirror.CodeMirror.CodeMirror) {
      height: 100%;
    }

    // overriding some codemirror defaults for accessibility
    :global(.CodeSnippet) {
      width: 100%;
    }

    // overriding theme gutters
    :global(.CodeMirror-gutters.CodeMirror-gutters) {
      background: white;
    }
  }

  &-footer {
    &-button {
      @include dark-mode {
        button {
          color: var(--gray40);

          &:hover, &:focus {
            color: var(--gray50) !important;
          }
        }
      }

      font-size: 12px;
      padding-bottom: var(--sm);
      padding-right: var(--sm);

      &-container {
        display: flex;
        justify-content: flex-end;
        width: 100%;
      }
    }
  }

  &-initial-state {
    @include dark-mode {
      color: var(--gray40);
    }

    color: var(--color-text-minimum);
    font-family: var(--font-family);
    font-size: 12px;
    font-weight: var(--font-weight-bold);
    padding: var(--md);
    text-align: center;
  }
}
