@import '~@core/styles/vars';

.APIAuth {
  // all the header things
  &-heading {
    color: var(--graphite);
    font-family: var(--font-family);
    font-size: 12px;
    font-weight: var(--font-weight-bold);
    text-transform: uppercase;
  }

  &-lock {
    border: 0;

    &:hover,
    &:active,
    &:focus {
      &:not(:disabled) {
        box-shadow: none;
      }
    }
  }

  &-oauth-missing-scope {
    color: var(--red);
  }

  &-oauth {
    &-menu {
      max-width: 300px;
    }

    &-error {
      color: var(--red);
      font-size: 12px;
    }

    &-scopes {
      padding: 2px 0;

      &-badge {
        height: 12px;
        padding: 0 4px;
      }

      &-icon {
        color: var(--color-text-minimum);
        font-size: 16px;
        margin-right: var(--xs);
      }

      &-required {
        align-items: center;
        color: var(--red);
        display: flex;
        font-size: 12px;
      }

      &-selector {
        display: flex;
        flex-wrap: wrap;
        gap: var(--xs);
        justify-content: flex-start;
        max-width: 100%;
      }

      &-text {
        display: block;
        max-width: 100%;
        word-wrap: break-word;
      }
    }

    &-detail,
    &-required-scopes-label,
    &-authorized-scopes,
    &-reauthorize-scopes {
      align-items: center;
      color: var(--color-text-minimum);
      display: flex;
      font-size: 12px;
    }

    &-authorized-scopes {
      margin-left: var(--xs);

      &-container {
        align-items: center;
        display: flex;
        justify-content: center;
      }

      &-label {
        margin-right: var(--xs);
      }
    }

    &-footer {
      border-top: 1px solid var(--color-border-default);
      padding-top: var(--xs);

      &_mini {
        border: 0;
        padding-bottom: var(--xs);
        padding-top: 0;
      }

      &-authorize {
        background: var(--color-primary);
      }

      &-authorize:hover {
        background: var(--color-primary-darken-10);
      }

      &-reauthorize {
        color: var(--color-primary);
      }

      &-reauthorize:hover,
      &-reauthorize:not(:disabled):hover {
        color: var(--color-primary-darken-10);
      }
    }

    &-required-scopes-tooltip,
    &-authorized-scopes-tooltip {
      margin: var(--xs) 0;
      padding-left: var(--md);
    }
  }

  &-oauthButton {
    border: 1px solid transparent;
    border-radius: var(--border-radius);
    color: var(--color-primary-inverse);
    font-family: var(--font-family);
    font-size: 12px;
    font-weight: var(--font-weight-bold);
    padding: $xs;
    text-decoration: none;
    transition: box-shadow var(--transition-fast) var(--transition-timing);

    &:focus {
      outline: 0;
    }

    // for the authorize state
    &_authorize {
      background: var(--color-primary, $blue);

      &:hover {
        background: var(--color-primary-darken-10, #{darken($blue, 10%)});
      }

      &:active {
        background: var(--color-primary-darken-20, #{darken($blue, 20%)});
      }

      &:active,
      &:focus {
        box-shadow: 0 0 0 3px var(--color-primary-alpha-25, #{rgba($blue, 0.25)});
      }
    }

    // for the "log out" button state
    &_logOut {
      color: $red;
      transition: box-shadow var(--transition-speed) var(--transition-timing);

      &:active,
      &:focus {
        border-color: $red;
        box-shadow: 0 0 0 3px rgba($red, 0.25);
      }
    }
  }

  // log in stuff
  &-login {
    color: var(--color-text-minimum);
    font-size: 14px;
    margin-top: $sm;

    &-icon {
      opacity: 0.5;
    }

    &-link {
      align-items: center;
      color: currentcolor;
      display: flex;
      text-decoration: none;

      &:hover,
      &:active,
      &:focus {
        color: var(--graphite);
      }
    }
  }

  // menus
  &-menu {
    &-header {
      font-family: var(--font-family);
    }

    &-item {
      align-items: center;
      display: flex;
      flex-direction: column;

      :global(.markdown-body) {
        color: var(--color-text-minimum);
        font-size: 12px;
        font-weight: var(--font-weight);

        > * {
          margin: 0;
        }
      }

      &-externalIcon {
        margin-left: 2px;
      }
    }

    &-subtitle {
      color: var(--color-text-minimum);
      font-size: 12px;
      font-weight: var(--font-weight);
    }

    &_loggedOut {
      &-message,
      &-buttonparent {
        padding: 10px 15px;
      }

      &-message {
        border-bottom: 1px solid var(--ivory);
        color: var(--graphite);
      }

      &-buttonparent {
        display: flex;
        justify-content: flex-end;

        a {
          background: var(--color-primary);
          border-radius: var(--border-radius);
          color: var(--color-primary-inverse);
          padding: 5px 7px;
          text-decoration: none;
        }
      }
    }
  }
}
