@import '~@core/styles/vars';
@import '~@core/styles/mixins/dark-mode';

.HeadersModal {
  &-body {
    // let the table wrapper handle it's right padding so it appears if x-axis overflows
    padding-right: 0;
  }

  &-subtitle {
    background: var(--gray100);
    color: var(--color-text-minimum);
    font-size: 12px;
    font-weight: var(--font-weight-bold);
    padding: $sm 0 $sm $sm;
    text-transform: uppercase;

    /* stylelint-disable-next-line order/order */
    @include dark-mode {
      background: var(--gray10);
    }
  }

  &-table-wrapper {
    min-width: $iphone-plus;
    overflow-x: auto;
    padding-right: $md;
    width: calc(100% - $md);
  }

  &-table {
    background: var(--white);
    border: var(--border-width) solid rgba(black, 0.1);
    border-radius: var(--border-radius-lg);
    color: var(--graphite);
    font-size: 12px;
    padding: $sm;
    width: 100%;
    word-break: break-all;

    /* stylelint-disable-next-line order/order */
    @include dark-mode {
      background: var(--gray15);
      color: var(--white);
    }

    td {
      padding-bottom: 3px;
      vertical-align: baseline;
    }

    td:first-child {
      max-width: 50%;
      min-width: 160px;
      padding-right: $sm;
      text-align: right;
      word-break: break-word;
    }

    td:not(:first-child):not(:last-child) {
      color: var(--minimumGray, var(--minimum-gray));
    }

    /* stylelint-disable-next-line no-descending-specificity */
    td:last-child {
      font-family: var(--font-family-mono);
      font-size: 11px;
      word-break: normal;
    }
  }
}
