@import '@core/styles/vars';

.PaginationControls {
  $block: &;

  align-items: flex-start;
  display: grid;
  grid-template-columns: 3fr 1fr 3fr;
  padding: $md 0;

  &-link {
    align-items: center;
    display: flex;

    &_left {
      &:hover,
      &:active,
      &:focus {
        #{$block}-icon {
          transform: translateX(-3px);
        }
      }

      &:active #{$block}-icon {
        color: var(--color-link-primary);
      }
    }

    &_right {
      grid-column: 3;
      justify-self: end;

      &:hover,
      &:active,
      &:focus {
        #{$block}-icon {
          transform: translateX(3px);
        }
      }

      &:active #{$block}-icon {
        color: var(--color-link-primary);
      }
    }
  }

  &-icon {
    color: var(--color-text-minimum-icon);
    transition:
      color var(--transition-slow) var(--transition-timing),
      transform var(--transition-slow) var(--transition-timing);
  }

  &-text {
    color: var(--color-text-muted);
    font-weight: var(--font-weight);
    line-height: 1.2;

    &_left {
      padding-left: $sm;
    }

    &_right {
      padding-right: $sm;
      text-align: end;
    }
  }

  &-meta {
    align-self: center;
    color: var(--color-text-minimum);
    display: flex;
    font-size: 13px;
    grid-column: 2;
    justify-self: center;
  }
}
