@import '~@core/styles/vars';
@import '~@core/styles/mixins/dark-mode';

.APIResponse {
  &-snippet {
    pre.CodeSnippet {
      height: auto;

      .CodeMirror {
        height: auto;
        overflow-x: hidden;

        &-scroll {
          min-height: 50px;
        }
      }
    }
  }

  &-action {
    &-button {
      letter-spacing: unset;

      .Badge {
        flex: 0 0 auto;
        font-size: 7px;
        padding: 2px 4px;
      }
    }

    &-icon {
      color: $shale;
    }

    &-icon_large {
      font-size: $icon-md;
    }
  }

  &-menu {
    min-width: 220px;

    &-header {
      color: $shale;
      font-size: 12px;
      font-weight: var(--font-weight);
      padding: var(--sm);

      &:not(:first-child) {
        border-top: 1px solid rgba(black, 0.1);
        margin-top: var(--xs);
        padding-top: var(--sm);
      }
    }

    &-item {
      font-size: 13px;
      padding: 0.5em 0.6em;

      &-wrapper {
        justify-content: space-between;
      }
    }

    &-status {
      margin: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      text-transform: capitalize;
      white-space: nowrap;
    }

    &-method {
      margin-right: $xs;
      min-width: 40px;
    }

    &-callbacks {
      color: var(--graphite);
      display: flex;
      flex-direction: column;
      margin: $sm;
      text-align: left;

      &-header {
        display: flex;
        flex: 0 1 auto;
        flex-direction: row;
        flex-wrap: nowrap;
        font-size: 14px;
        max-width: 150px;
        padding-bottom: 2px;

        &-name {
          margin-right: $xs;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      &-example {
        font-size: 12px;
        font-weight: var(--font-weight-normal);
      }
    }

    &-status-wrapper,
    &-item-wrapper {
      align-items: center;
      display: flex;
      overflow: hidden;
    }

    &-check {
      color: var(--gray60);
      padding-right: 3px;
    }

    &-divider {
      background: rgba($black, 0.05);
      box-shadow: 0 1px 0 rgba($white, 0.025);
      height: 1px;
      padding: 0;
    }
  }
}
