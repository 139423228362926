@import '@core/styles/vars';

.Flyout {
  animation: flyout var(--transition-fast) var(--transition-timing);
  background: var(--Flyout-background, white);
  box-sizing: border-box;
  display: none;
  height: 100vh;
  inset: var(--SuperHub-top-nav-height, 0) auto 0 0;
  max-width: var(--Flyout-width);
  overflow-y: auto;
  position: absolute;
  transition: transform var(--transition-fast) var(--transition-timing);
  width: 100%;
  z-index: 999;

  @keyframes flyout {
    0% {
      transform: translateX(-100px);
    }

    100% {
      transform: none;
    }
  }

  &_opened {
    display: block;
  }

  &_overlay {
    background: rgba(black, 0.1);
    inset: 0;
    position: fixed;
    z-index: 100;
  }
}
