/* stylelint-disable custom-property-pattern */
/* stylelint-disable selector-max-compound-selectors */
@import '~@core/styles/vars';
@import '~@core/styles/mixins/dark-mode';
@import '../../ui/Button/style';

.GraphQLPlayground,
.GraphQLPlayground * {
  box-sizing: border-box;
}

.GraphQLPlayground {
  margin-left: auto;
  margin-right: auto;
  max-width: var(--container-lg);
  width: 100%;

  // visibility hidden so that screen readers don't read our hidden text
  %hidden {
    font-size: 0;
    visibility: hidden;
  }

  // title
  %title {
    font-size: 12px;
    font-weight: var(--font-weight-bold);
    padding-bottom: $sm;
    text-transform: uppercase;
  }

  :global {
    // everything but the UI editor
    .graphiql-container {
      --GraphQL-bg: var(--gray10);
      --GraphQL-sidebar-width: 250px;
      --GraphQL-syntax-atom: #f78c6c;
      --GraphQL-syntax-attribute: #d7b2f0;
      --GraphQL-syntax-builtin: #ffcb6b;
      --GraphQL-syntax-comment: #9091B2;
      --GraphQL-syntax-def: #82aaff;
      --GraphQL-syntax-keyword: #d7b2f0;
      --GraphQL-syntax-meta: #ffcb6b;
      --GraphQL-syntax-number: #ff5370;
      --GraphQL-syntax-operator: #89ddff;
      --GraphQL-syntax-property: #d7b2f0;
      --GraphQL-syntax-qualifier: #decb6b;
      --GraphQL-syntax-string: #c3e88d;
      --GraphQL-syntax-tag: #ff5370;
      --GraphQL-syntax-variable: #f07178;
      --GraphQL-syntax-variable-2: #eff;
      --GraphQL-syntax-variable-3: #decb6b;

      @include dark-mode {
        --GraphQL-bg: var(--gray15);
      }

      box-shadow: inset -1px 0 var(--color-border-default);
      display: flex;
      flex: 1;
      max-width: calc(100% - var(--GraphQL-sidebar-width));
      min-height: calc(100vh - 91px); // viewport height - header

      // wraps controls and editor
      .editorWrap {
        display: flex;
        flex: 1;
        flex-direction: column;
        max-width: calc(100% - var(--GraphQL-sidebar-width));
        min-width: var(--GraphQL-sidebar-width);
        order: 2;
        padding: $md;

        // controls
        .topBarWrap {
          background: var(--GraphQL-bg);
          border-bottom: 1px solid rgba(white, 0.075);
          border-left: 1px solid var(--color-border-default);
          border-radius: var(--border-radius-lg) var(--border-radius-lg) 0 0;
          border-right: 1px solid var(--color-border-default);
          border-top: 1px solid var(--color-border-default);
          box-shadow: inset 0 -1px 0 rgba(black, 0.15);
          padding: $sm;

          .topBar {
            display: flex;
            justify-content: space-between;

            // title
            .title {
              display: none;
            }

            // toolbar
            .toolbar {
              align-items: center;
              display: flex;

              button {
                display: none;
              }

              [title='Copy Query (Shift-Ctrl-C)'] {
                @extend %hidden;

                cursor: pointer;
                display: flex;
                opacity: 0.6;

                &::before {
                  color: #fff;
                  content: '\ea0c'; // clipboard
                  font-family: ReadMe-Icons;
                  font-size: $icon-md;
                  visibility: visible;
                }

                &:hover,
                &:active,
                &:focus {
                  opacity: 0.8;
                }
              }
            }

            // play button
            .execute-button-wrap {
              order: 1;

              .execute-button {
                --TryIt-background: var(--color-primary, #018ef5);
                --TryIt-background-hover: var(--color-primary-darken-10, #0272d9);
                --TryIt-background-active: var(--color-primary-darken-20, #0158a7);
                --TryIt-background-focus: var(--color-primary-alpha-25, rgba(17, 140, 253, 0.25));
                --TryIt-background-disabled: var(--color-primary-darken-20, #0158a7);
                --TryIt-border-radius: var(--border-radius-lg);
                --TryIt-color: var(--color-primary-inverse, #fff);

                background: var(--TryIt-background);
                border: 0;
                border-radius: var(--TryIt-border-radius);
                color: var(--TryIt-color);
                cursor: pointer;
                height: $button-md;
                padding: 0 15px;
                transition: box-shadow var(--transition-slow) var(--transition-timing);

                // icon
                svg {
                  display: none;
                }

                &::before {
                  content: 'Try It!';
                  font-family: var(--font-family);
                  font-size: 16px;
                  font-weight: var(--font-weight-bold);
                }

                &:hover {
                  background: var(--TryIt-background-hover);
                }

                &:active {
                  background: var(--TryIt-background-active);
                }

                &:focus {
                  box-shadow: 0 0 0 3px var(--TryIt-background-focus);
                }
              }
            }
          }
        }

        // editors (query and response)
        .editorBar {
          display: flex;
          flex: 1;
          flex-direction: column;

          .queryWrap {
            background: var(--GraphQL-bg);
            border-bottom: 1px solid var(--color-border-default);
            border-left: 1px solid var(--color-border-default);
            border-radius: 0 0 var(--border-radius-lg) var(--border-radius-lg);
            border-right: 1px solid var(--color-border-default);
            box-shadow: var(--Box-card-shadow);
            flex: 0 !important;
          }
        }

        .secondary-editor {
          background: var(--GraphQL-bg);
          border-radius: 0 0 var(--border-radius-lg) var(--border-radius-lg);
          border-top: 1px solid rgba(black, 0.15);
          box-shadow: inset 0 1px 0 rgba(white, 0.075);
          max-height: 200px;
          padding-top: $sm;

          // variables and headers tabs
          .secondary-editor-title {
            font-size: 13px;
            padding-left: $md;
            pointer-events: none;

            &.variable-editor-title {
              padding-left: $xs;
            }

            // tabs
            div {
              @extend %hidden;

              color: white !important; // override inline style
              opacity: 0.6;
              padding: $xs;
              pointer-events: auto;

              &.active {
                opacity: 1;
              }

              &::before {
                @extend %title;

                visibility: visible;
              }

              &:first-child::before {
                content: 'VARIABLES';
              }

              &:last-child {
                margin-left: $sm !important; // override inline style

                &::before {
                  content: 'HEADERS';
                }
              }

              &:hover,
              &:active,
              &:focus {
                opacity: 0.8;
              }
            }
          }

          .CodeMirror {
            max-height: 160px;
          }
        }

        // query menu
        .CodeMirror-hints {
          // recreating Menu
          --Menu-background-hover: rgba(255, 255, 255, 0.05);
          --Menu-background-active: rgba(255, 255, 255, 0.075);
          --Menu-divider: rgba(255, 255, 255, 0.075);
          --Menu-header: var(--chalk);
          --Menu-item: var(--white);

          background: var(--gray20);
          border-radius: var(--border-radius);
          box-shadow:
            0 5px 10px rgba(black, 0.1),
            0 2px 6px rgba(black, 0.1),
            0 1px 3px rgba(black, 0.05),
            inset 0 -1px 1px rgba(black, 0.1);
          color: var(--Menu-item);
          font-size: 13px;
          list-style: none;
          margin: $xs 0 0;
          padding: $xs 0;
          position: absolute;
          width: 250px;

          .CodeMirror-hint,
          .CodeMirror-hint-information {
            padding: $xs $sm;
          }

          .CodeMirror-hint {
            cursor: pointer;
            font-weight: var(--font-weight);

            &:hover {
              background: var(--Menu-background-hover);
            }

            &.CodeMirror-hint-active,
            &:active,
            &:focus {
              background: var(--Menu-background-active);
            }
          }

          .CodeMirror-hint-information {
            border-top: 1px solid var(--Menu-divider);
            margin-top: $xs;
            padding-top: $sm;

            .content {
              align-items: center;
              display: flex;

              .infoType {
                border: 1px solid rgba(white, 0.1);
                border-radius: var(--border-radius);
                color: rgba(white, 0.9);
                font-family: var(--font-family-mono);
                font-size: 11px;
                margin-left: $xs;
                order: 2;
                padding: 2px $xs;
              }

              p {
                align-items: flex-start;
                display: flex;
                margin: 0;
              }
            }
          }
        }

        // result
        .resultWrap {
          background: var(--GraphQL-bg);
          border: 1px solid var(--color-border-default);
          border-radius: var(--border-radius-lg);
          box-shadow: var(--Box-card-shadow);
          flex: 1;
          margin-top: $md;
          overflow: hidden;
          padding-left: $xs;

          &::before {
            @extend %title;

            color: var(--gray70);
            content: 'RESPONSE';
            display: block;
            padding: $sm $xs;
          }
        }
      }

      // explorer
      .docExplorerWrap {
        box-shadow: inset -1px 0 var(--color-border-default);
        color: var(--color-text-muted);
        flex: 0 0 auto;
        max-height: 100vh;
        order: 1;
        overflow-y: auto;
        padding: $md;
        position: sticky;
        top: 0;
        width: var(--GraphQL-sidebar-width) !important;

        .doc-explorer {
          display: flex;
          flex-direction: column;
        }

        // hide title and close button
        .doc-explorer-title,
        .doc-explorer-rhs {
          display: none;
        }

        // title bar with back button
        .doc-explorer-title-bar {
          border-top: 1px solid var(--color-border-default);
          display: flex;
          margin-top: $sm;
          order: 2;
          padding-top: $sm;
        }

        // back button
        .doc-explorer-back {
          @extend %hidden;

          border: 0;
          padding: 0;

          &::before {
            align-items: center;
            background: var(--color-input-background);
            border: 1px solid var(--color-border-default);
            border-radius: var(--border-radius);
            bottom: 0;
            color: var(--color-text-minimum);
            content: '\ea4b'; // arrow-left
            cursor: pointer;
            display: inline-flex;
            font-family: ReadMe-Icons;
            font-size: $icon-md;
            height: $button-sm;
            justify-content: center;
            left: 0;
            position: sticky;
            visibility: visible;
            width: $button-sm;
            z-index: 1;
          }
        }

        // explorer nav
        .doc-explorer-contents {
          order: 1;

          // search empty state
          .doc-alert-text {
            display: block;
            font-size: 13px;
            margin-top: var(--sm);
          }

          // search list
          .doc-search-items {
            font-size: 13px;
            overflow-wrap: break-word;
            padding-bottom: var(--sm);
            padding-top: var(--sm);
          }
        }

        // search input
        .search-box {
          align-items: center;
          display: flex;
          position: relative;

          // search icon
          .search-box-icon {
            display: none;
          }

          // clear icon
          .search-box-clear {
            background: none;
            border: 0;
            color: var(--color-text-minimum);
            position: absolute;
            right: var(--xs);
          }

          // recreating .Input
          input {
            --Input-background: var(--color-input-background);
            --Input-color: var(--color-input-text);
            --Input-border: var(--color-input-border);
            --Input-border-hover: var(--color-input-border-hover);
            --Input-border-active: var(--color-input-border-active);
            --Input-border-focus: var(--color-input-border-focus);
            --Input-placeholder: var(--gray60);

            @include dark-mode {
              --Input-disabled-bg: var(--gray30);
              --Input-disabled-color: var(--gray60);

              color-scheme: light dark;
            }

            background: var(--Input-background);
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgb(147, 158, 174)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-search'%3E%3Ccircle cx='11' cy='11' r='8'%3E%3C/circle%3E%3Cline x1='21' y1='21' x2='16.65' y2='16.65'%3E%3C/line%3E%3C/svg%3E");
            background-position: $xs center;
            background-repeat: no-repeat;
            background-size: $icon-md;
            border: var(--border-width) solid var(--Input-border);
            border-radius: var(--border-radius);
            box-sizing: border-box;
            color: var(--Input-color);
            font-family: inherit; // this is needed for date types in Chrome
            font-size: 13px;
            font-weight: var(--font-weight-normal);
            height: var(--button-sm);
            padding-left: $xs + $icon-md + $xs;
            padding-right: $sm;
            transition: box-shadow var(--transition-slow) var(--transition-timing);
            width: 100%;

            // Remove extra padding in Safari—looks like they left room for an icon, but no icon appears
            &::-webkit-search-decoration {
              appearance: none;
            }

            &:hover {
              border-color: var(--Input-border-hover);
            }

            &:active,
            &:focus {
              border-color: var(--Input-border-active);
              box-shadow: 0 0 0 3px var(--Input-border-focus);
              outline: none;
            }

            &::placeholder {
              color: var(--Input-placeholder);
            }
          }
        }

        // explorer description
        .doc-type-description {
          font-size: 13px;

          code {
            background: rgba(black, 0.025);
            border: 1px solid var(--color-border-default);
            border-radius: var(--border-radius);
            display: inline-block;
            font-size: 13px;
            padding: 0 2px;
          }
        }

        .doc-category {
          border-top: 1px solid var(--color-border-default);
          font-size: 13px;
          overflow-wrap: break-word;
          padding-top: 1em;

          a {
            color: var(--blue);
            cursor: pointer;
            font-size: 13px;
            text-decoration: underline;

            &:hover,
            &:active,
            &:focus {
              color: var(--blue40);
            }
          }

          &-item + .doc-category-item {
            margin-top: 2px;
          }
        }

        // headings
        .doc-category-title {
          @extend %title;
        }
      }

      // just the UI editor
      + .docExplorerWrap {
        box-shadow: none;
        color: var(--color-text-muted);
        flex: 0 0 auto;
        max-height: 100vh;
        overflow-y: auto !important;
        padding: $md 15px;
        position: sticky;
        top: 0;
        width: var(--GraphQL-sidebar-width) !important;

        // hide close button
        // hide the query title bar
        .docExplorerHide,
        .graphiql-operation-title-bar {
          display: none;
        }

        // hide the query explorer footer
        .graphiql-explorer-actions {
          display: none !important; // override inline style
        }

        // title bar
        .doc-explorer-title-bar {
          @extend %hidden;

          &::before {
            @extend %title;

            content: 'QUERY EDITOR';
            padding: 0 $xs;
            visibility: visible;
          }
        }

        // the whole thing
        .graphiql-explorer-root {
          padding: $xs 0 !important;

          > div {
            overflow: visible !important;

            > div {
              border-bottom-color: var(--color-border-default) !important; // override inline style
              margin-bottom: $xs !important; // override inline style
              padding-bottom: $xs !important; // override inline style

              // for some reason this div has tabIndex="0"
              &:focus {
                outline: 0;
              }
            }
          }
        }

        // all checks
        .Toggle {
          pointer-events: none;

          &-display {
            height: 12px;
            margin-right: $xs;
            width: 12px;

            &-icon {
              font-size: 8px;
            }
          }
        }

        .graphiql-explorer-node {
          // chevrons
          .icon {
            color: var(--gray60);
            font-size: 12px;
            margin-right: $xs;
          }

          // variables in arguments
          .graphiql-explorer-id {
            align-items: center;
            display: flex;
          }

          .toolbar-button {
            background: var(--color-input-background);
            border: 1px solid var(--color-border-default) !important; // override inline style
            border-radius: var(--border-radius);
            color: var(--color-text-muted);
            cursor: pointer;
            font-family: var(--font-family-mono);
            height: 16px !important; // override inline style
            margin-left: $xs !important; // override inline style
            padding: 0 2px !important; // override inline style
            width: auto !important; // override inline style

            &:not(:last-child) {
              margin-right: $xs !important; // override inline style
            }

            &:hover {
              border-color: var(--color-border-default);
            }

            &:active,
            &:focus {
              border-color: var(--blue) !important;
              box-shadow: 0 0 0 3px rgba($blue, 0.25);
              outline: none;
            }

            &::before {
              content: 'VAR';
            }

            span {
              display: none;
            }
          }

          // argument inputs
          .graphiql-explorer-graphql-arguments input {
            background: var(--color-input-background);
            border: 1px solid var(--color-input-border) !important; // override inline style
            border-radius: var(--border-radius);
            height: 13px;
            margin-left: $xs;
            min-width: 40px;

            &:hover {
              border-color: var(--color-input-border-hover);
            }

            &:active,
            &:focus {
              border-color: var(--color-input-border-active) !important;
              box-shadow: 0 0 0 3px var(--color-input-border-focus);
              outline: none;
            }
          }
        }
      }

      // our theme; pasting it here so we don’t have to load another resource
      .CodeMirror,
      .CodeMirror-gutters {
        background-color: var(--GraphQL-bg) !important;
        border: 0;
        color: #f8f8f2 !important;
      }

      .CodeMirror-gutters {
        color: var(--GraphQL-bg);
      }

      .CodeMirror-cursor {
        border-left: solid thin #f8f8f0;
      }

      .CodeMirror-linenumber {
        color: #6d8a88;
      }

      .CodeMirror-selected {
        background: rgba(255, 255, 255, 0.1);
      }

      .CodeMirror-line::selection,
      .CodeMirror-line > span::selection,
      .CodeMirror-line > span > span::selection {
        background: rgba(255, 255, 255, 0.1);
      }

      .CodeMirror-line::selection,
      .CodeMirror-line > span::selection,
      .CodeMirror-line > span > span::selection {
        background: rgba(255, 255, 255, 0.1);
      }

      span.cm-atom {
        color: var(--GraphQL-syntax-atom);
      }

      span.cm-attribute {
        color: var(--GraphQL-syntax-attribute);
      }

      span.cm-builtin {
        color: var(--GraphQL-syntax-builtin);
      }

      span.cm-comment {
        color: var(--GraphQL-syntax-comment);
      }

      span.cm-def {
        color: var(--GraphQL-syntax-def);
      }

      span.cm-keyword {
        color: var(--GraphQL-syntax-keyword);
      }

      span.cm-meta {
        color: var(--GraphQL-syntax-meta);
      }

      span.cm-number {
        color: var(--GraphQL-syntax-number);
      }

      span.cm-operator {
        color: var(--GraphQL-syntax-operator);
      }

      span.cm-property {
        color: var(--GraphQL-syntax-property);
      }

      span.cm-qualifier {
        color: var(--GraphQL-syntax-qualifier);
      }

      span.cm-string,
      span.cm-string-2 {
        color: var(--GraphQL-syntax-string);
      }

      span.cm-tag {
        color: var(--GraphQL-syntax-tag);
      }

      span.cm-variable {
        color: var(--GraphQL-syntax-variable);
      }

      span.cm-variable-2 {
        color: var(--GraphQL-syntax-variable-2);
      }

      span.cm-variable-3 {
        color: var(--GraphQL-syntax-variable-3);
      }

      .CodeMirror-activeline-background {
        background: rgba(255, 255, 255, 0.1);
      }

      .CodeMirror-matchingbracket {
        color: white !important;
        text-decoration: underline;
      }
    }

    // clickable nodes
    .graphiql-explorer-node,
    .graphiql-explorer-node + div [class^='graphiql-explorer-'] {
      font-family: var(--font-family);
      font-size: 13px;

      // the actual click target
      > span {
        align-items: center;
        border: 1px solid transparent;
        border-radius: var(--border-radius);
        display: flex;
        padding: 2px $xs;
        transition: box-shadow var(--transition-slow) var(--transition-timing);

        &:hover {
          border-color: var(--color-border-default);
        }

        &:active,
        &:focus {
          border-color: var(--color-input-border-hover);
        }
      }
    }

    // errors
    .error-container {
      background: var(--gray100);
      border: 1px solid rgba(black, 0.05);
      border-radius: var(--border-radius);
      font-size: 13px;
      margin-top: 10px;
      padding: 10px;
      width: 100%;
    }
  }
}
