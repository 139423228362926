@import '~@core/styles/vars';
@import '~@core/styles/mixins/dark-mode';

.APIResponse {
  --APIResponse-bg: var(--gray100);
  --APIResponse-border: #{rgba(black, 0.1)};
  --APIResponse-title-color: var(--color-text-minimum);
  --APIResponse-hr: #{rgba(white, 0.05)};
  --APIResponse-hr-shadow: #{rgba(black, 0.1)};
  --APIResponse-shadow: var(--box-shadow-request);

  @include dark-mode {
    --APIResponse-bg: var(--gray15); /* matching Box */
    --APIResponse-border: var(--color-border-default); /* matching Box */
    --APIResponse-hr-shadow: inset 0 1px 0 #{rgba(white, 0.15)}, inset 0 -1px 2px #{rgba(black, 0.1)};
    --APIResponse-shadow: var(--Box-card-shadow, 0 1px 2px #{rgba(black, 0.1)}, 0 2px 5px #{rgba(black, 0.1)});
  }

  background: var(--APIResponse-bg);
  border: var(--border-width) solid var(--APIResponse-border);
  border-radius: var(--border-radius-lg);
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-top: $sm;

  &_asCodeSnippet {
    min-height: 200px;
  }

  &-header,
  &-footer {
    align-items: center;
    display: flex;
    flex: 0 0 auto;
    justify-content: space-between;
    padding: var(--sm);
  }

  &-header {
    border-bottom: 1px solid var(--APIResponse-hr);
    box-shadow: inset 0 -1px 0 var(--APIResponse-hr-shadow);
    font-size: 12px;
    font-weight: var(--font-weight-bold);
    overflow: hidden;

    &-title {
      color: var(--APIResponse-title-color);
      flex: 1 0 auto;
      font-family: var(--font-family);
      text-transform: uppercase;
    }

    /* Button here raises specificity so !important doesn’t have to be used */
    button.APIResponse-header-tab {
      height: auto;
      letter-spacing: unset;
      padding: 0;

      &:hover,
      &:focus {
        border: 1px solid transparent;
        border-color: transparent;
        box-shadow: none;
        color: var(--APIResponse-title-color);
      }

      &-active,
      &-disabled {
        color: var(--APIResponse-title-color);
        opacity: 1;
      }

      &-disabled {
        cursor: unset;
      }
    }

    .APIResponse-action-button {
      width: 100%;
    }
  }

  &-footer {
    border-top: 1px solid var(--APIResponse-hr);
    box-shadow: inset 0 1px 0 var(--APIResponse-hr-shadow);
  }

  &-action {
    flex: 0 1 auto;
    overflow: hidden;
  }

  &-copyButton {
    @include dark-mode {
      color: var(--color-text-minimum);

      &:not(:disabled):hover,
      &:not(:disabled):focus-visible {
        color: var(--color-text-minimum-hover);
      }
    }

    border: 0;
    color: var(--color-text-minimum);

    &:not(:disabled):hover,
    &:not(:disabled):focus-visible {
      color: var(--color-text-minimum-hover);
      outline: 0;
    }
  }

  &-empty {
    align-items: center;
    color: var(--color-text-minimum);
    display: flex;
    flex-direction: column;
    font-size: 14px;
    justify-content: center;
    max-height: 38vh;
    overflow: auto;
    padding: 30px;
    text-align: center;

    &-tryit {
      background: rgba(black, 0.05);
      border: 1px solid var(--color-border-default);
      border-radius: var(--border-radius);
      color: var(--color-text-muted);
      font-family: var(--font-family);
      font-weight: var(--font-weight-bold);
      padding: 1px 3px;
    }

    &-examples {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      max-width: 100%;

      &-list {
        max-width: 100%;
        overflow: hidden;

        + .APIResponse-empty-examples-list {
          border-top: 1px solid var(--color-border-default);
          padding-top: var(--md);
        }
      }

      &-overflow {
        max-width: 100%;
      }
    }

    &-button,
    &-callback {
      align-items: center;
      background: none;
      border-radius: var(--border-radius);
      color: var(--color-text-muted);
      cursor: pointer;
      display: flex;
      font-family: var(--font-family);
      font-weight: var(--font-weight-bold);
      overflow: hidden;
      text-overflow: ellipsis;
      transition: box-shadow var(--transition-fast) var(--transition-timing);

      &:hover {
        border: 1px solid var(--color-border-default);
      }

      &:active,
      &:focus {
        border-color: $blue;
        box-shadow: 0 0 0 3px rgba($blue, 0.25);
      }

      &:focus {
        outline: 0;
      }
    }

    &-button {
      border: 1px solid transparent;
    }

    &-callback {
      border: 1px solid var(--color-border-default);
    }
  }

  :global {
    .CodeSnippet {
      flex: 1;
      margin-bottom: 0;
      margin-top: 0;
      overflow: hidden;

      .CodeEditor,
      .CodeMirror {
        height: 100% !important;
      }
    }
  }
}
