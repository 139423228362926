@import '@core/styles/mixins/dark-mode';

.APIDesigner {
  --ParamContainer-bg: var(--gray100);
  --ParamContainer-bg-alt: var(--white);
  --ParamContainer-border: var(--color-border-default);
  --ParamContainer-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
  --ParamContainer-color: var(--color-text-default);

  @include dark-mode {
    --ParamContainer-bg: var(--gray15);
    --ParamContainer-bg-alt: var(--gray10);
  }

  border-top: 1px solid var(--color-border-default);
  padding-top: var(--sm);

  &_disabled {
    border-bottom: 1px solid var(--color-border-default);
    margin-top: var(--md);
    padding-bottom: var(--md);
  }

  &-beta {
    @include dark-mode {
      background: rgba(white, 0.1);
    }

    border-top: 1px solid var(--color-border-default);
    color: var(--color-text-minimum);
    font-size: 12px;
    margin-top: var(--sm);
    padding: var(--sm) var(--xs);

    &-email {
      color: currentcolor;
      text-decoration: underline;

      &:hover,
      &:active,
      &:focus {
        text-decoration: none;
      }
    }
  }

  &-alert {
    &-link {
      color: currentcolor;
      text-decoration: underline;

      &:hover,
      &:active,
      &:focus {
        color: currentcolor;
        text-decoration: none;
      }
    }
  }

  &-skeleton,
  &-skeleton-header {
    font-size: 10px;
  }
}


.Parameter {
  $block: &;

  background: var(--ParamContainer-bg);
  border: 1px solid var(--ParamContainer-border);
  border-top: 1px solid var(--ParamContainer-border);
  box-shadow: var(--ParamContainer-box-shadow);
  padding: var(--sm);

  &:not(:first-child) {
    margin-top: -1px;
  }

  &:first-of-type {
    border-top-left-radius: var(--border-radius-lg);
    border-top-right-radius: var(--border-radius-lg);
  }

  &:last-child {
    border-bottom-left-radius: var(--border-radius-lg);
    border-bottom-right-radius: var(--border-radius-lg);
  }

  &:only-child {
    border-radius: var(--border-radius-lg);
  }

  &_skeleton {
    @include dark-mode {
      background: transparent;

      &-value {
        background: var(--gray20);
      }
    }

    &-value {
      background: var(--Core-white, #FFF);
      border: 1px solid rgba(0, 0, 0, 0.10);
      border-radius: 5px;
      height: 30px;
      width: 300px;
    }
  }

  &-error {
    color: var(--red);
    font-size: 13px;
  }

  &-group {
    $param-inputs: 162px; // magic number of input + button

    display: grid;
    gap: var(--xs);
    grid-template-columns: minmax(0, calc(100% - $param-inputs)) 1fr;
  }

  &-inputSizer {
    font-size: 12px;
    min-width: 7ch;
    padding: 4px 8px; // match input padding + double on left/right
    position: relative;

    &-clone {
      display: inline-block;
      height: 1px;
      opacity: 0;
      pointer-events: none;
      white-space: nowrap;
    }

    .Parameter-input {
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }

  &-description,
  &-input {
    align-items: center;
    background: none;
    border: 1px solid transparent;
    border-radius: var(--border-radius);
    box-sizing: border-box;
    color: var(--color-input-text);
    display: inline-flex;
    font-family: var(--font-family);
    font-size: 12px;
    font-variant-numeric: tabular-nums;
    margin: 0;
    min-width: 7ch;
    padding: 4px;
    transition: box-shadow var(--transition-slow) var(--transition-timing);

    &::placeholder {
      color: var(--color-text-minimum);
    }

    &_name {
      font-weight: var(--font-weight-bold);
    }

    &_required {
      color: var(--color-text-minimum);
      cursor: pointer;
      gap: var(--xs);
      position: relative;
      text-decoration: line-through;

      &_checked {
        color: var(--red);
        text-decoration: none;
      }

      &:hover {
        border-color: var(--color-input-border);
      }
    }

    &_type {
      background: none;
      color: var(--color-text-minimum);
      cursor: pointer;
      white-space: nowrap;
    }

    &_form {
      background: var(--color-input-background);
      border-color: var(--color-input-border);
    }

    // for the inputs that are inputs
    &_form,
    &_name,
    &_type {
      &:hover {
        border-color: var(--color-input-border);
      }

      &:active,
      &:focus {
        background: var(--color-input-background);
        border-color: var(--color-input-border-active);
        box-shadow: 0 0 0 3px var(--color-input-border-focus);
        outline: 0;
      }

      &#{$block}-input_error {
        border-color: var(--red);

        &:focus-visible {
          border-color: var(--red);
          box-shadow: 0 0 0 3px rgba(var(--red-rgb), 0.4);
        }
      }
    }

    &-checkbox {
      position: absolute;
      visibility: hidden;
    }
  }

  &-description {
    font-size: 13px;

    &:focus {
      outline: 0;
    }
  }
}
