@import '@core/styles/vars';
@import '@core/styles/mixins/dark-mode';

.PageThumbs {
  display: table;
  margin: auto;
  padding: $lg $md;

  &-helpful {
    align-items: center;
    border: 1px solid var(--color-border-default);
    border-radius: var(--border-radius-lg);
    color: var(--color-text-minimum);
    column-gap: $sm;
    display: flex;
    flex-wrap: wrap;
    font-size: 14px;
    justify-content: center;
    padding: $xs $sm;

    p {
      margin-bottom: 17px;
    }
  }

  &-cta {
    display: flex;
    justify-content: space-evenly;
  }

  &-button {
    border-color: 1px solid transparent;
    padding: 0 $sm;

    .icon {
      font-size: var(--icon-md);
      transform: scale(1);
      transition: $transition-fast transform $transition-timing;
    }

    &_selected {
      .icon {
        // Increase CSS specificity here to override underlying rules.
        &.icon {
          opacity: 1;
          transform: scale(1.1);
          transform-origin: center;
        }

        &-thumbs-up-2 {
          color: $green;
        }

        &-thumbs-down1 {
          color: $red;
        }
      }
    }

    &:focus {
      border-color: rgba(black, 0.15);
    }
  }

  &-comment {
    border: 1px solid var(--color-border-default);

    &-body {
      padding: $sm;
    }

    &-footer {
      border-top: 1px solid var(--color-border-default);
      padding: $sm;
    }

    &_box {
      padding: 0;
      width: 250px;

      &::before,
      &::after {
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
        border-top: 7px solid transparent;
        content: '';
        display: block;
        height: 0;
        left: 0;
        margin-left: auto;
        margin-right: auto;
        position: absolute;
        right: 0;
        width: 0;
      }

      &::before {
        @include dark-mode {
          border-top-color: #414a4f; // Hex equivalent of --color-border-default over --gray15
        }

        border-top-color: var(--color-border-default);
        bottom: -7px;
      }

      &::after {
        @include dark-mode {
          border-top-color: var(--gray15);
        }

        border-top-color: var(--color-bg-page);
        bottom: -6px;
      }
    }

    &_input {
      margin-top: $sm;
      max-height: 500px;
      min-height: 70px;

      &:focus {
        border-width: 1px;
      }
    }

    &_submit {
      background-color: var(--color-link-primary);
      color: var(--color-link-text);
      width: 100%;

      &:hover {
        background-color: var(--color-link-primary-darken-10);
        color: var(--color-link-text);
      }

      &:disabled {
        background-color: var(--color-link-primary-alpha-50);
      }
    }
  }
}
