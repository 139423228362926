.OperationEditor {
  color: var(--color-text-default);
  font-size: 13px;
  width: 100%;

  &-method {
    display: inline-flex;
    margin-top: 2px;
    position: relative;

    &-pill {
      display: inline-flex;

      &-icon {
        font-size: var(--icon-sm);
      }
    }

    &-select {
      cursor: pointer;
      inset: 0;
      opacity: 0;
      position: absolute;

      &:disabled {
        cursor: default;
      }
    }
  }

  &-path,
  &-path-field {
    min-width: 0;
  }

  &-inputSizer {
    position: relative;
    width: 100%;

    &-clone {
      display: inline-block;
      height: 1px;
      opacity: 0;
      pointer-events: none;
      white-space: nowrap;
    }
  }

  &-server-url {
    flex: 0 0 auto;
  }

  &-input {
    all: unset;
    border: 1px solid var(--color-input-border);
    border-radius: var(--border-radius);
    color: var(--color-input-text);
    font-variant-numeric: tabular-nums;
    left: 0;
    margin: 0;
    min-width: 50px;
    padding: 0;
    position: absolute;
    top: -1px;
    transition: box-shadow var(--transition-slow) var(--transition-timing);
    width: 100%;

    &:hover {
      border-color: var(--color-input-border-hover);
    }

    &:active,
    &:focus {
      border-color: var(--color-input-border-active);
      box-shadow: 0 0 0 3px var(--color-input-border-focus);
      outline: 0;
    }

    &::before {
      color: var(--color-text-minimum);
      opacity: 0.5;
    }

    &:empty::before {
      content: attr(placeholder);
    }

    &:not(:empty):focus::before {
      content: '';
    }

    &::placeholder {
      color: var(--color-text-minimum);
    }

    &_error {
      border-color: var(--red);

      &:active,
      &:focus {
        border-color: var(--red);
        box-shadow: 0 0 0 3px rgba(var(--red-rgb), 0.4);
      }
    }
  }

  &-error {
    color: var(--red);
    font-size: 12px;
  }
}
