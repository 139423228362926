@import '@core/styles/vars';

.FileUploader {
  min-width: 130px;
  position: relative;
  vertical-align: middle;

  &-preview {
    background-color: var(--color-bg-page);
    border-radius: var(--border-radius-lg);
    box-shadow: 0 0 0 1px var(--color-border-default);
    box-sizing: border-box;
    font-weight: var(--font-weight);
    height: 40px;
    padding: 0;
    width: 200px;

    &-control {
      background: none;
      border: 0;
      color: var(--color-text-default);
      cursor: pointer;
      flex: 1 1 auto;
      font-size: 12px;
      font-weight: var(--font-weight);
      min-width: 0;
      padding: 0 var(--xs);

      &:first-child {
        padding-left: var(--sm);
      }

      &:last-child {
        padding-right: var(--sm);
      }

      &:hover {
        color: var(--color-text-minimum-hover);
      }

      &_remove {
        flex: 0 0 auto;
        font-weight: var(--font-weight-bold);
        text-transform: uppercase;
      }
    }

    &-image {
      flex: 0 0;
      max-height: 25px;
      max-width: 70px;
    }

    &-label {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;
    }

    &_full-width {
      width: 100%;
    }

    // Match preview size to button sizes.
    &_size {
      &-xs {
        height: $button-xs;
      }

      &-sm {
        height: $button-sm;
      }

      &-md {
        height: $button-md;
      }

      &-lg {
        height: $button-lg;
      }
    }
  }
}
