@import '~@core/styles/vars';

.rm-TryIt {
  --TryIt-background: var(--color-primary, #{$blue});
  --TryIt-background-hover: var(--color-primary-darken-10, #{darken($blue, 10%)});
  --TryIt-background-active: var(--color-primary-darken-20, #{darken($blue, 20%)});
  --TryIt-background-focus: var(--color-primary-alpha-25, #{rgba($blue, 0.25)});
  --TryIt-background-disabled: var(--color-primary-darken-20, #{darken($blue, 20%)});
  --TryIt-border-radius: var(--border-radius-lg);
  --TryIt-color: var(--color-primary-inverse);
  --TryIt-spinner-color: var(--color-primary-inverse);

  &_replay.Button {
    background: transparent;
    border-color: rgba(white, 0.1);
    color: white;

    &:hover {
      background: transparent;
      border-color: rgba(white, 0.2);
    }

    &:active,
    &:focus-within {
      background: transparent;
      border-color: var(--TryIt-background);
      box-shadow: 0 0 0 3px var(--TryIt-background-focus);
    }

    &:disabled,
    &:disabled:hover,
    &:disabled:active {
      background: transparent;
      color: white;
    }
  }
}
